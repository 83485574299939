import FadeDiv from "../../../utilities/FadeDiv";
import { Link } from 'react-router-dom'

export default function Header(){
    return (
        <header className="flex select-none text-xs lg:text-sm xl:text-md 2xl:text-base h-[10vh] justify-center py-2">
            <FadeDiv className="container px-4 h-full flex justify-center flex-col">
                <Link to="/" className="w-fit"><h1 className="capitalize 2xl:text-2xl text-base lg:text-xl">yumazine games project</h1></Link>
                <a className="w-fit" href="https://www.yumazine.com" rel="no-ref">go to portofolio</a>
            </FadeDiv>
        </header>
    )
}