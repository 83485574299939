import { Link } from "react-router-dom";

export default function Nav(){
    return (
        <div id="nav">
            <div id="nav-container">
                <Link className="nav-item" to="/">
                    <span>Home</span>
                </Link>
                <span></span>
                <Link className="nav-item" to="galleries">
                    <span>Galleries</span>
                </Link>
                <span></span>
                <Link className="nav-item" to="lore">
                    <span>Lore</span>
                </Link>
                <span></span>
                <Link className="nav-item dropdown" to="characters">
                    <div className="nav-triangle"></div>
                    <span>Characters</span>
                    <div className="dropdown-container">
                        <div className="dropdown-mover">
                            <div className="dropdown-item">
                                <span>Elesis</span>
                                &nbsp;(Knight)
                            </div>
                            <div className="dropdown-item">
                                <span>Ara</span>
                                &nbsp;(Little Xia)
                            </div>
                            <div className="dropdown-item">
                                <span>Add</span>
                                &nbsp;(Tracer)
                            </div>
                            <div className="dropdown-item">
                                <span>Lu/Ciel</span>
                                &nbsp;(Demons)
                            </div>
                        </div>
                    </div>
                </Link>
                <span></span>
                <Link className="nav-item" to="preRegister">
                    <span>Pre-Register</span>
                </Link>
            </div>
        </div>
    )
}