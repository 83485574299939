import { Link } from 'react-router-dom'

export default function GameItems(){


    function getPlayerIcon(player){
        if(player === undefined) return ""
        return !player ? 
            <div className="w-full h-full relative flex justify-center items-center">
                <div className="absolute h-full w-1 dark:bg-fc-dark-brightest bg-fc-brightest rotate-45"></div>
                <div className="absolute h-full w-1 dark:bg-fc-dark-brightest bg-fc-brightest -rotate-45"></div>
            </div> 
            : <div className="w-full h-full border-4 rounded-full dark:border-fc-dark-brightest border-fc-brightest">
            </div>
    }

    const tictactoeImage = () => {
        return (
            <div className='flex justify-center items-center flex-col h-full w-full'>
                <div className="flex mb-1">
                    <div className="h-10 w-10 mr-1 dark:bg-neutral3-dark bg-neutral3 p-1">{getPlayerIcon(0)}</div>
                    <div className="h-10 w-10 mr-1 dark:bg-neutral3-dark bg-neutral3"></div>
                    <div className="h-10 w-10 dark:bg-neutral3-dark bg-neutral3"></div>
                </div>
                <div className="flex mb-1">
                    <div className="h-10 w-10 mr-1 dark:bg-neutral3-dark bg-neutral3"></div>
                    <div className="h-10 w-10 mr-1 dark:bg-neutral3-dark bg-neutral3"></div>
                    <div className="h-10 w-10 dark:bg-neutral3-dark bg-neutral3 p-1">{getPlayerIcon(1)}</div>
                </div>
                <div className="flex">
                    <div className="h-10 w-10 mr-1 dark:bg-neutral3-dark bg-neutral3"></div>
                    <div className="h-10 w-10 mr-1 dark:bg-neutral3-dark bg-neutral3"></div>
                    <div className="h-10 w-10 dark:bg-neutral3-dark bg-neutral3"></div>
                </div>
            </div>
        )
    }

    const game2048ColorLists = [
        '#0d9165',
        '#147e58',
        '#176b4c',
        '#18593f',
        '#184733',
        '#163628',
        '#12261d',
        '#0c1712',
        '#a71442',
        '#841835',
        '#631829',
        '#000000',
    ]

    const game2048Image = () => {
        let row = []
        let a = 2048
        for(let i = 0; i < 4; i++){
            let col = []
            for(let j = 0; j < 4; j++){
                col.push(
                    <div key={"2048"+j} className={`h-8 w-8 
                        ${ (j < 3 && i % 2 === 0) || (j > 0 && i % 2 === 1) ? "mr-0.5" : ""} 
                        dark:bg-neutral3-dark bg-neutral3`}>
                        {a !== 0 ? <div className='h-full w-full text-xs text-fc-dark-brighter text-center flex justify-center items-center'
                            style={{backgroundColor: game2048ColorLists[Math.log2(a)]}}>{a}</div> : ""}
                    </div>
                )
                a >>= 1
                if(a===1) a = 0
            }
            if(i % 2 === 1)
                col=col.reverse()

            row.push(
                <div key={"2048"+i} className={`flex ${ i > 0 ? "mb-0.5" : ""}`}>
                    {col}
                </div>
            )
        }
        row = row.reverse()
        return <div className='flex justify-center items-center flex-col h-full w-full'>{row}</div>
    }

    const snakeImage = () => {
        let row = []
        for(let i = 0; i < 5; i++){
            let col = []
            for(let j = 0; j < 5; j++){
                col.push(
                    <div key={"snek"+j} className={`h-6 w-6
                        ${ i === 4 && j === 4 ? "bg-complementary-dark" :
                        i === 4 && j === 2 ? "bg-primary-dark" : (i === 0) || (j >= 4 && i <= 2) || (i === 2 && j > 0)
                            || (i === 4 && j > 0 && j < 3) || (i === 3 && j === 1)
                        ? "bg-primary" : 
                        (i % 2 === j % 2) ? "bg-neutral2 dark:bg-neutral2-dark" : "bg-neutral3 dark:bg-neutral3-dark" }`}>
                    </div>
                )
            }

            row.push(
                <div key={"snek"+i} className={`flex`}>
                    {col}
                </div>
            )
        }
        return <div className='flex justify-center items-center flex-col h-full w-full'><div className='bg-neutral3'>{row}</div></div>
    }

    const memoryGameImage = () => {
        const BUTTON_COLOR = [
            'bg-red-600', 'bg-orange-500', 'bg-yellow-400',
            'bg-green-600', 'bg-blue-600', 'bg-purple-700'
        ]

        let row = []
        for(let i = 0; i < 3; i++){
            let col = []
            for(let j = 0; j < 2; j++){
                col.push(
                    <div key={"snek"+j} className={`h-10 w-10
                        ${ j > 0 ? "ml-1" : ""}
                        ${ BUTTON_COLOR[i * 2 + j] }`}>
                    </div>
                )
            }

            row.push(
                <div key={"snek"+i} className={`flex ${ i > 0 ? "mt-1" : ""}`}>
                    {col}
                </div>
            )
        }
        return <div className='flex justify-center items-center flex-col h-full w-full'>{row}</div>
    }

    const items = [
        ["TicTacToe", true, 'tictactoe', tictactoeImage()],
        ["2048", true, '2048', game2048Image()],
        ["Snake", true, 'snake', snakeImage()],
        ["Memory Game", true, 'memory_game', memoryGameImage()],
    ]

    function renderItem(item){
        return (
            <Link className={`w-64 h-48 md:w-[22rem] md:h-60 lg:w-80 lg:h-52 2xl:w-[22rem] 2xl:h-60 xl:w-96 xl:h-64 flex flex-col bg-neutral1 dark:bg-neutral1-dark rounded-sm overflow-hidden 
                mb-8 md:mx-0 mx-4 lg:mb-4 xl:mb-12 2xl:mb-8`} key={item} to={item[2]}>
                <div className='flex-grow'>{item[3]}</div>
                <div className='flex justify-center p-2 dark:text-fc-dark-brighter text-fc-brighter'>{item[0]}</div>
            </Link>
        )
    }

    return (
        <section id="game-items" className="min-h-[88vh] xl:py-12 flex justify-center">
            <div className='container h-fit px-4 flex justify-center md:justify-between flex-wrap'>
                {items.map((e) => {return renderItem(e)})}
            </div>
        </section>
    )
}