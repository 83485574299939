import { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import bg1 from "../assets/movingbg/movingbg1.jpg"
import bg2 from "../assets/movingbg/movingbg2.jpg"
import bg3 from "../assets/movingbg/movingbg3.jpg"

export default function Header(){

    const refs = useRef([])
    refs.current = []
    function addToRefs(el){
        if(el && !refs.current.includes(el))
            refs.current.push(el)
    }

    const movingBg = [bg1, bg2, bg3]
    const [movingBgStyle, setMovingBgStyle] = useState([
        {top: 0, left: 0, transform: "translate(0,0)"},
        {top: 0, right: 0},
        {}
    ])
    const [activeIdx, setActiveIdx] = useState(0)

    function renderMovingBg(){
        return movingBg.map((e, idx) => {
            return <img key={e} ref={addToRefs} style={movingBgStyle[idx][activeIdx === idx]}
            className={`moving-bg ${activeIdx === idx ? "moving-bg-active" : ""}`} src={e} alt=""/>
        })
    }

    const timerDuration = 8000
    const timer = useRef(null)
    useEffect(() => {
        if(timer.current !== null){
            clearTimer()
            initializeTimer()
        }
    }, [activeIdx])

    useEffect(() => {
        setMovingBgStyle([
            [{top: 0, left: 0, transform: "translate(0,0)"}, 
            {top: 0, left: 0, transform: `translate(-${refs.current[0].width - window.innerWidth}px, -${refs.current[0].height - window.innerHeight}px)`}],
            [{top: 0, right: 0},{}],
            [{},{}]
        ])
        initializeTimer()
    }, [])

    function initializeTimer(){
        if(timer.current !== null) return;
        timer.current = setInterval(() => {
            setActiveIdx((activeIdx + 1) % movingBg.length)
        }, timerDuration)
    }

    function clearTimer(){
        if(timer.current === null) return

        clearInterval(timer.current)
        timer.current = null
    }

    return (
        <div id="header">
            <img src={logo} alt=""/>
            {window.innerWidth}
            <div id="moving-background-container">
                <div id="home-moving-background">
                    {renderMovingBg()}
                    {/* <img className="moving-bg moving-bg-active" src={bg1} style={{top: 0, left: 0}} alt=" "/>
                    <img className="moving-bg" src={bg2} alt=""/>
                    <img  className="moving-bg" src={bg3} alt=""/> */}
                </div>
            </div>
        </div>
    )
}