import '../../css/home.css'
import slider1 from "../../assets/image-slider/image-slider1.jpg"
import slider2 from "../../assets/image-slider/image-slider2.jpg"
import slider3 from "../../assets/image-slider/image-slider3.jpg"
import slider4 from "../../assets/image-slider/image-slider4.jpg"
import slider5 from "../../assets/image-slider/image-slider5.jpg"
import slider6 from "../../assets/image-slider/image-slider6.jpg"
import left from "../../assets/image-slider/side-left.png"
import right from "../../assets/image-slider/side-right.png"
import { useRef, useState } from 'react'
import { useEffect } from 'react'

export default function Home(){

    const slider = [slider1, slider2, slider3, slider4, slider5, slider6]
    const [activeIdx, setActiveIdx] = useState(0)

    function renderSliderNav(){
        return slider.map((e, idx) => {
            return <div key={e} className={`circle-dot ${activeIdx === idx ? "circle-dot-active" : ""}`}></div>
        })
    }

    const timerDuration = 5000
    const timer = useRef(null)
    useEffect(() => {
        if(timer.current !== null){
            clearTimer()
            initializeTimer()
        }
    }, [activeIdx])

    useEffect(() => {
        initializeTimer()
    }, [])

    function initializeTimer(){
        if(timer.current !== null) return;
        timer.current = setInterval(() => {
            setActiveIdx((activeIdx + 1) % slider.length)
        }, timerDuration)
    }

    function clearTimer(){
        if(timer.current === null) return

        clearInterval(timer.current)
        timer.current = null
    }

    return (
        <div className='relative w-[100vw] min-h-[80vh] flex justify-center items-center'>
            <div id="side-image">
                <img src={left} alt=""/>
                <img src={right} alt=""/>
            </div>
            <div id="home">
                <div id="slider-container">
                    <div id="slider-top">
                        <div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                    <div id="slider" style={{transform: `translateX(calc(-${activeIdx} * 40vw))`}}>
                        <img src={slider1} alt=""/>
                        <img src={slider2} alt=""/>
                        <img src={slider3} alt=""/>
                        <img src={slider4} alt=""/>
                        <img src={slider5} alt=""/>
                        <img src={slider6} alt=""/>
                    </div>
                    <div id="slider-nav">
                        <div>
                            {renderSliderNav()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}