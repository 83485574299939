import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

export default function MemoryGame({setSystemMessage}){

    const MEMORY_SIZE = 6

    const [gameState, setGameState] = useState({
        state: 'waiting',
        highScore: 0,
        score: 0
    })
    const [memoryList, setMemoryList] = useState([])
    const [playbackList, setPlaybackList] = useState([])
    const [activeIdx, setActiveIdx] = useState(-1)
    const currentEvaluate = useRef(0)

    const randomInt = (max, skip = undefined) => {
        if(skip === undefined)
            return Math.floor(Math.random() * (max + 1));
        return (Math.floor(Math.random() * max) + skip + 1) % (max + 1)
    }

    const TIMEOUT_DURATION = 500
    const TIMEOUT_DELAY_DURATION = 250
    const INITIAL_TIMEOUT_DURATION = 2000
    const pressButton = (idx) => {
        if(gameState.state !== 'playing') return
    
        let evaluate = memoryList[currentEvaluate.current]
        if(evaluate !== idx){
            setGameState({
                ...gameState,
                state: 'end'
            })
            return
        }

        currentEvaluate.current++
        if(currentEvaluate.current < memoryList.length) return
        let ml = JSON.parse(JSON.stringify(memoryList))
        ml.push(randomInt(MEMORY_SIZE - 1))
        setMemoryList(ml)
        setTimeout(() => {
            setPlaybackList(ml)
        }, INITIAL_TIMEOUT_DURATION)
        currentEvaluate.current = 0

        let score = gameState.score + 1
        setGameState({
            state: 'waiting',
            score: score,
            highScore: score > gameState.highScore ? score : gameState.highScore
        })
        if(score > gameState.highScore)
            localStorage.setItem('memoryHighScore', score)
    }

    useEffect(() => {
        if(activeIdx !== -1) 
        setTimeout(() => {
            setActiveIdx(-1)
        }, TIMEOUT_DELAY_DURATION)

    }, [activeIdx])

    useEffect(() => {
        if(playbackList.length === 0){
            setGameState({
                ...gameState,
                state: 'playing'
            })
            setActiveIdx(-1)
        } else {
            let pl = JSON.parse(JSON.stringify(playbackList))
            setActiveIdx(pl.shift())
            setTimeout(() => {
                setPlaybackList(pl)
            }, TIMEOUT_DURATION + TIMEOUT_DELAY_DURATION)
        }
    // eslint-disable-next-line
    }, [playbackList])

    useEffect(() => {
        let hs = localStorage.getItem('memoryHighScore')
        setGameState({
            ...gameState,
            state: 'initial',
            highScore: hs === null ? 0 : hs
        })
        
        let ml = JSON.parse(JSON.stringify(memoryList))
        ml.push(randomInt(MEMORY_SIZE - 1))
        setMemoryList(ml)
    // eslint-disable-next-line
    }, [])

    const startGame = () => {
        let ml = JSON.parse(JSON.stringify(memoryList))
        setGameState({
            ...gameState,
            state: 'waiting',
        })
        setTimeout(() => {
            setPlaybackList(ml)
        }, INITIAL_TIMEOUT_DURATION)
    }

    const initialize = () => {
        if(gameState.state === 'waiting') return
        currentEvaluate.current = 0
        setGameState({
            ...gameState,
            score: 0,
            state: 'waiting'
        })

        let ml = []
        ml.push(randomInt(MEMORY_SIZE - 1))
        setMemoryList(ml)
        setTimeout(() => {
            setPlaybackList(ml)
        }, INITIAL_TIMEOUT_DURATION)
    }

    const resetButton = () => {
        if(gameState.state === 'initial')
            return <div className="sm:w-fit w-full bg-complementary-dark hover:bg-complementary transition active:bg-complementary-dark select-none cursor-pointer py-3 px-7 mb-4 rounded-sm uppercase text-center" 
                onClick={() => startGame()}>
                    Start
            </div>
        return <div className="sm:w-fit w-full bg-complementary-dark hover:bg-complementary transition active:bg-complementary-dark select-none cursor-pointer py-3 px-7 mb-4 rounded-sm uppercase text-center" 
            onClick={() => initialize()}>
                Restart
        </div>
    }

    const renderMemoryButton = () => {
        const BUTTON_COLOR = [
            'bg-red-600', 'bg-orange-500', 'bg-yellow-400',
            'bg-green-600', 'bg-blue-600', 'bg-purple-700'
        ]

        let ret = []
        for(let i = 0; i < MEMORY_SIZE; i++)
        ret.push(
            <div key={i} onClick={() => pressButton(i)} className={`${ gameState.state === 'playing' ? "active:bg-primary-brighter" : ""} 
                min-w-[6rem] min-h-[6rem] sm:min-w-[9rem] sm:min-h-[9rem] mb-4 sm:mb-8 ${ activeIdx === i ? "bg-primary-brighter" : BUTTON_COLOR[i]}`}>

            </div>
        )

        return ret
    }

    return(
        <section id="memoryGame" className="h-[90vh] xl:py-12 flex flex-col items-center justify-center relative">
            <div className="container px-4 grow flex items-center flex-col justify-center">
                <div className="flex flex-col mb-6 items-center">
                    <div className="flex">    
                        <div className="bg-primary-dark text-fc-dark-brighter py-2 px-4 rounded-md mr-4 w-32 sm:w-40">
                            <div className="uppercase text-center text-sm">score</div>
                            <div className="font-semibold text-center text-2xl">{gameState.score}</div>
                        </div>
                        <div className="bg-primary-dark text-fc-dark-brighter py-2 px-4 rounded-md w-32 sm:w-40">
                            <div className="uppercase text-center text-sm">high score</div>
                            <div className="font-semibold text-center text-2xl">{gameState.highScore}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex select-none text-fc-dark-brightest justify-center">
                        {resetButton()}
                        {/* {memoryList} */}
                    </div>
                    <div className="bg-neutral1 dark:bg-neutral1-dark flex flex-wrap justify-between
                    w-60 sm:w-96 mt-4 relative px-4 pt-4 sm:pt-8 sm:px-8">
                        {renderMemoryButton()}
                        <div className={`absolute top-0 left-0 bg-black/25 text-fc-dark-brightest w-full h-full justify-center items-center flex-col`} style={{display: gameState.state === 'end' ? 'flex' : 'none'}}>
                            <div className="text-4xl font-bold">GAME OVER!</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}