import '../../css/preRegister.css'

export default function PreRegister(){
    return(
        <div id="preRegister" style={{minHeight: "80vh"}}>
            <div>
                <div id="register-header">
                    <h1>Register</h1>
                </div>
                <div id="form-container">
                    <form method="post">
                        <div className="form-section">
                            <div className="inp">
                                <input type="text" name="email" id="email" placeholder=" "/>
                                <span>Email</span>
                            </div> 
                            <div className="inp">
                                <input type="text" name="username" id="username" placeholder=" "/>
                                <span>Username</span>
                            </div> 
                            <div className="inp">
                                <input type="password" name="password" id="password" placeholder=" "/>
                                <span>Password</span>
                            </div>
                            <div className="left-right">
                                <span>Nation</span>
                                <select name="Nation" id="nation">
                                    <option value="" default hidden>Please Choose a Nation</option>
                                    <option value="Indonesia">Indonesia</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Malaysia">Malaysia</option>
                                    <option value="Australia">Australia</option>
                                    <option value="Japan">Japan</option>
                                    <option value="China">China</option>
                                </select>
                            </div>
                            <div className="left-right" id="gender">
                                <span>Gender</span>
                                <input type="radio" name="gender" id="maleGender" value="Male"/>
                                <label htmlFor="maleGender">
                                    <p>Male</p> 
                                </label>
                                <input type="radio" name="gender" id="femaleGender" value="Female"/>
                                <label htmlFor="femaleGender">
                                    <p>Female</p>
                                </label>
                            </div>
                            <div className="left-right">
                                <span>Date of Birth</span>
                                <input type="date" name="dob" id="dob"/>
                            </div>
                            <div id="submit-btn">
                                <div id="userTerm">
                                    <input type="checkbox" name="agree" id="agree" style={{display: "none"}}/>
                                    <label htmlFor="agree">
                                        <div className="checkbox">
                                            <div></div>
                                        </div>
                                        Agreement to user terms
                                    </label>
                                </div>
                                <input type="submit" value="Submit"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}