import FadeDiv from "../../../../utilities/FadeDiv"
import SlideInDiv from "../../../../utilities/SlideInDiv"

export default function Header(){

    const navList = [
        ["About Me",
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>, "#aboutme"],
        ["Experiences",
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
        </svg>, "#experiences"],
        ["Skills",
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
        </svg>, "#skills"],
        ["Projects",
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
        </svg>
        // <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        //     <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
        // </svg>
        , "#projects"],
        ["Publication & Competition",
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
        </svg>, "#pubcom"],
        // ["Contacts",
        // <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        //     <path strokeLinecap="round" strokeLinejoin="round" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
        // </svg>, "#contacts"]
    ]

    function renderNavList(){
        return navList.map((item) => {
            return <a href={item[2]} key={item[0]} className="flex items-center cursor-pointer 
            font-semibold tracking-wide mx-2 py-1 px-4 hover:bg-primary 
            rounded-md group overflow-hidden hover:text-white">
                <div className="hidden xl:block">{item[1]}</div>
                <div className="xl:pl-2 xl:group-hover:max-w-[250px] xl:max-w-0 transition-[max-width] 
                duration-1000  overflow-hidden whitespace-nowrap">{item[0]}</div>
            </a>
        })
    }

    function changeTheme(){
        // console.log(localStorage.theme)
        if(localStorage.theme === "dark"){
            document.documentElement.classList.remove('dark')
            localStorage.theme = "light"
        }
        else{
            document.documentElement.classList.add('dark')
            localStorage.theme = "dark"
        }
    }

    return(
        <header id="top" className="flex select-none text-xs lg:text-sm xl:text-md 2xl:text-base h-[12vh] justify-center py-2">
            <FadeDiv className="container px-4 h-full flex justify-end md:justify-between items-center">
                <SlideInDiv>
                    <div onClick={() => changeTheme()} className="flex 
                    bg-primary rounded-md border-2 border-primary
                    shadow-[0px_3px_8px] dark:shadow-neutral2-dark shadow-neutral2 
                    text-white h-10 lg:h-12 px-4 justify-center items-center font-bold text-base xl:text-xl tracking-widest">DF19-1</div>
                </SlideInDiv>
                <SlideInDiv isLeft={false}>
                    <div className="hidden md:flex border-2 rounded-md border-primary py-2 dark:bg-neutral1-dark bg-neutral1">{renderNavList()}</div>
                </SlideInDiv>
            </FadeDiv>
        </header>
    )
}