import Contacts from "../contents/Contacts";

export default function Footer(){
    return (
        <footer className="min-h-[5rem] py-5 dark:bg-neutral1-dark bg-neutral1 flex flex-col items-center justify-center ">
            <div className="container px-4">
                <div className="border-t pt-3 dark:border-fc-dark border-fc flex items-center flex-col">
                    <Contacts/>
                    <div className="capitalize text-xs text-center mt-3 pt-2 dark:border-fc-dark text-fc-brighter dark:text-fc-dark-brighter border-fc border-t w-fit">
                        &copy; 2023 yumazine. all rights reserved.
                    </div>
                </div>
            </div>
        </footer>
    )
}