import { useEffect, useRef, useState } from "react"
import allnewatlantis from '../../images/projects/allnewatlantis.png'
import kvdf from '../../images/projects/kvdf.png'
import skripsi from '../../images/projects/skripsi.png'
import badakDashboard from '../../images/projects/badak_dashboard.png'
import badakProxy from '../../images/projects/badak_proxy.png'
import spade from '../../images/projects/spade_dashboard.png'
import projhci from '../../images/projects/projecthci.png'
import FadeDiv from '../../../../utilities/FadeDiv'
import FadeInDiv from '../../../../utilities/FadeInDiv'

export default function Projects(){
    
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    // const isSafari = true

    function getProjectImage(src){
        if(typeof src === 'string')
            return <img className="w-full h-auto lg:w-auto lg:h-full z-10 lg:rounded-b-none" src={src} alt=""/>
        return <div className="px-8 aspect-video lg:aspect-auto h-full w-full lg:rounded-b-none">{src}</div>
    }

    const projectLists = [
        // ["AllNewAtlantis", "A Software Laboratory Center internal web application that built using .NET for data availabilitya purposes."],
        // ["AllNewAtlantis", "A Software Laboratory Center internal web application that built using .NET for data availabilitbbay purposes."],
        // ["AllNewAtlantis", "A Software Laboratory Center internal web application that built using .NET for data availabilitbbay purposes."],
        ["AllNewAtlantis", "A Software Laboratory Center internal web application that built using .NET for data availability purposes.",
        null, allnewatlantis],
        ["Yumazine Games Project", "My own game project for learning purposes.", "https://games.yumazine.com",
            <div className="h-full z-10 w-full font-semibold flex justify-center items-center">
                <span className="text-3xl text-center">Yumazine Games Project</span>
            </div>
        ],
        ["Line Follower Smart Trolley System V2", "Line-Follower mecanum robot, built using Arduino. Line-Follower system uses Fuzzy-PID controller to make correction and a RFID scanner for localization.",
        "https://youtu.be/4Jr7QfLMdVw", skripsi],
        ["HCI Project", "A simple website made for Human and Computer Interaction course lab project score using basic html, css, and js. Created back in 2018. (PC Only)",
        "https://projhci.yumazine.com", projhci],
        ["KumVulanDFreelancer", "A mobile application for dedicated to freelancers and clients seeking freelancer. Built using Kotlin for Software Laboratory Center assistant progressive test.",
        "https://github.com/sannaggi/KumVulanDFreelancer", kvdf],
        ["Badak Dashboard", "A web application dashboard to view QA automation reports. Built using Vue and Spring boot.", null, badakDashboard],
        ["Badak Proxy Dashboard", "A dashboard to manage payment proxy to be used in the QA automation. Built using Vue and Spring boot.", null, badakProxy],
        ["Spade Dashboard", "A web application to view page and API performances report of Blibli page. Built using plotly dash app.", null, spade],
        // ["AllNewAtlantis", "A Software Laboratory Center internal web application that built using .NET for data availabilitbbay purposes."],
    ]

    const [activeIdx, setActiveIdx] = useState({
        isLeft: true,
        value: Math.trunc( projectLists.length / 2 )
    })

    const timerDuration = 5000
    const timer = useRef(null)

    function initializeTimer(){
        if(timer.current !== null) return;
        timer.current = setInterval(() => {
            changeActiveIdx((activeIdx.value + 1) % projectLists.length)
        }, timerDuration)
    }

    function clearTimer(){
        if(timer.current === null) return;

        clearInterval(timer.current)
        timer.current = null
    }

    useEffect(() => {
        if(timer.current !== null){
            clearInterval(timer.current)
            timer.current = null
            timer.current = setInterval(() => {
                changeActiveIdx((activeIdx.value + 1) % projectLists.length)
            }, timerDuration)
        }
    }, [activeIdx, projectLists.length])

    function getTransformIdx(idx){
        let add = activeIdx - idx
        let ret = -add + 4
        ret = ret < 0 ? 0 : ret
        ret = ret > 8 ? 8 : ret
        return ret
    }

    //safari exclusive
    function getSafariStyle(){
        if(!isSafari) return {}
        return {
            left: 'calc(26rem * ' + activeIdx + ' * -1)'
        }
    }

    function getVisitProject(idx){
        let url = projectLists[idx][2]
        if(url === null) return (
            <div className={`lg:flex hidden sm:absolute top-0 right-0 items-center sm:m-2 sm:p-2 m-1 py-1 pl-2 pr-3 mt-3 w-full sm:w-fit
                dark:bg-neutral1-dark bg-neutral1 text-xs lg:text-sm
                justify-center
                shadow-[0px_3px_8px] shadow-neutral2-dark 
                rounded-md cursor-default z-20`}>
                <div className="mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-5 lg:w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                </div>
                Private Project
            </div>
        )
        
        return <a href={url} target="_blank" rel="noreferrer" 
        className={`sm:absolute top-0 w-full sm:w-fit
        right-0 hidden lg:flex items-center sm:m-2 sm:p-2 py-1 pl-2 pr-3 mt-3
        dark:bg-primary-darker bg-primary-dark text-xs sm:text-sm
        text-fc-dark-brightest
        shadow-[0px_3px_8px] shadow-neutral2-dark
        justify-center rounded-md z-20`}>
            <div className="mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:h-5 lg:w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>
            </div>
            Visit Project
        </a>
    }

    function changeActiveIdx(newIdx) {
        setActiveIdx(prev => {
            return {
                isLeft: newIdx < prev.value,
                value: newIdx
            }
        })
    }

    function renderProjectList({p, idx}){
        return <div key={p+idx} className={`group transition-[width_height] duration-300 
            ${ idx === activeIdx.value ? "lg:w-4/5 xl:w-3/4 lg:h-full z-30" : 
            idx === activeIdx.value - 1 || idx === activeIdx.value + 1 ? "lg:w-1/12 xl:w-[7.5%] lg:h-[90%] z-20" : 
            idx === activeIdx.value - 2 || idx === activeIdx.value + 2 ? "lg:w-0 lg:h-1/4 xl:w-[5%] xl:h-2/3" : 
            "lg:w-0 lg:h-1/4"} w-full
        `}>
            <div onClick={() => {changeActiveIdx(idx)}} onMouseEnter={clearTimer} 
            onMouseLeave={initializeTimer} className={`
            lg:shadow dark:shadow-neutral2-dark shadow-neutral3
            ${isSafari ? (idx === 0 ? '' : 'ml-4') : ""} ${activeIdx.value === idx ? '' : 'lg:cursor-pointer'} 
                h-full flex flex-col relative overflow-hidden z-0
                ${ idx === activeIdx.value ? "rounded-md" : idx < activeIdx.value ? "rounded-l-md" :
                    "rounded-r-md"}
            `}>
                <div className={`w-full lg:w-auto h-full lg:aspect-video
                 overflow-hidden flex justify-center bg-center
                 lg:absolute rounded-md lg:rounded-none z-0
                 ${ idx === activeIdx.value ? (activeIdx.isLeft ? "left-0" : "right-0") : idx < activeIdx.value ? "left-0" :
                  "right-0"}
                 bg-neutral3 dark:bg-neutral3-dark`}>
                    <div className="hidden lg:block h-full w-full absolute bg-center filter blur-md" style={{backgroundImage: `url(${p[3]})`}}></div>
                    <div className={`hidden ${activeIdx.value === idx ? 'cursor-default lg:hidden' : 'cursor-pointer lg:block'}
                     transition-[opacity_background-opacity] duration-300 group-hover:bg-opacity-30 bg-opacity-0 bg-neutral1-dark absolute w-full h-full z-20`}>
                    </div>
                    <div className={`transition-[opacity] duration-300
                        ${idx === activeIdx.value ? "opacity-100" : "opacity-0"}
                    `}>{getVisitProject(idx)}</div>
                    {getProjectImage(p[3])}
                </div>
                {/* {transformStyles[getTransformIdx(idx)]} */}
                {/* {getTransformIdx(idx)} <br /> {idx} <br /> {activeIdx} */}
                <div className={`bottom-0 p-4 flex
                flex-col bg-neutral2 dark:bg-neutral2-dark w-2/3 lg:w-auto lg:h-full lg:aspect-video duration-300
                transition-opacity lg:left-0 z-30 lg:dark:bg-transparent lg:bg-transparent
                ${idx === activeIdx.value ? "" : "lg:hidden"} lg:justify-end
                -translate-y-1/2 lg:translate-y-0 ${idx % 2 === 0 ? "left-4" : "right-4 text-end"} 
                ${ idx === activeIdx.value ? (activeIdx.isLeft ? "lg:left-0" : "lg:right-0") : 
                idx < activeIdx.value ? "lg:left-0" : "lg:right-0"}
                rounded-md absolute z-10 lg:text-fc-bright lg:dark:text-fc-dark-bright`}>
                    <div className="lg:w-2/3 h-fit lg:bg-neutral1 lg:dark:bg-neutral2-dark lg:p-4 rounded-md
                    lg:shadow lg:dark:shadow-neutral2-dark lg:shadow-neutral3">
                        <div className="font-semibold lg:text-left text-lg lg:text-xl underline decoration-2
                        decoration-primary-dark dark:decoration-primary">{p[0]}</div>
                        <div className={`${idx % 2 === 0 ? "" : "text-end"} lg:text-left text-sm`}>{p[1]}</div>
                    </div>
                </div>
                <div className={`lg:hidden opacity-0 bottom-0 lg:pt-2 lg:h-full pb-4 lg:px-3 px-4 pt-4 flex 
                flex-col bg-neutral2 dark:bg-neutral2-dark w-2/3
                ${idx % 2 === 0 ? "left-4" : "right-4 text-end"} 
                rounded-md lg:rounded-t-none static z-10`}>
                    <div className="font-semibold text-lg lg:text-xl underline decoration-2 decoration-primary-dark dark:decoration-primary">{p[0]}</div>
                    <div className={`${idx % 2 === 0 ? "" : "text-end"} text-sm`}>{p[1]}</div>
                </div>
            </div>
        </div>
    }

    function renderProjectLists(){
        // if(isSafari)
        //     return <div className={`absolute flex`}>{projectLists.map((p, idx) => {
        //         return renderProjectList({p, idx})
        //     })}</div>
        return projectLists.map((p, idx) => {
            return renderProjectList({p, idx})
        })
    }

    const buttonActive = [
        "bg-neutral3 dark:bg-neutral3-dark",
        "bg-primary"
    ]

    function renderCarouselButton(){
        let indents = []
        for(let i = 0; i < projectLists.length; i++){
            indents.push(<div key={i} className={`mx-1`}>
                <div onClick={() => changeActiveIdx(i)} onMouseEnter={clearTimer} onMouseLeave={initializeTimer}
                className={`w-8 h-2 ${buttonActive[+(activeIdx.value === i)]} 
                shadow-[0px_3px_8px] dark:shadow-neutral2-dark shadow-neutral2 
                transition rounded-sm cursor-pointer`}></div>
            </div>)
        }
        return indents
    }

    return (
        <section id="projects" className="relative lg:pb-12 dark:bg-neutral1-dark bg-neutral1 flex flex-col items-center snap-center
         flex-shrink-0 lg:mt-0 mt-12 pt-12 lg:pt-0 z-0">
            <FadeInDiv className='container z-10 px-4 text-3xl xl:text-4xl underline lg:mt-12 lg:mb-8 text-center
            decoration-primary text-fc-brighter dark:text-fc-dark-brighter font-semibold mb-5'>Projects</FadeInDiv>
            <FadeInDiv initialize={initializeTimer} className={`container px-4 relative z-10 w-full`}>
               <div className={`lg:min-h-[10rem] mb-4 lg:gap-4 select-none flex justify-center lg:flex-row flex-col w-full`}>
                    {/* <div className={`snap-center flex-shrink-0 ${width}`}></div> */}
                    <div className={`relative z-10 w-full flex justify-center`}>
                        {/* <div className={`${ isSafari ? 'absolute h-full flex transition-[left] duration-700' : ''}`}
                        style={getSafariStyle()}> */}
                        <div className="lg:w-4/5 xl:w-3/4 lg:aspect-video"></div>
                        <div className="lg:absolute w-full lg:h-full left-0 top-0 flex flex-col lg:flex-row items-center justify-center">
                            {renderProjectLists()}
                        </div>
                        {/* </div> */}
                    </div>
                    {/* <div className={`snap-center flex-shrink-0 ${width}`}></div> */}
                </div>
                <div className="hidden lg:flex justify-center w-full mt-8">
                    {renderCarouselButton()}
                </div>
                <div></div>
            </FadeInDiv>
            <div className="-z-10 hidden lg:block h-1/2 absolute w-full dark:bg-neutral2-dark bg-neutral2 top-0 left-0"></div>
        </section>
    )
}