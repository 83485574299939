import { useCallback, useEffect, useState } from "react";

export default function ToTopButton(){
    const [isVisible, setIsVisible] = useState(true);
    const [height, setHeight] = useState(0)
    const heightToHideFrom = 200;

    const listenToScroll = useCallback(() => {
        const winScroll = document.body.scrollTop || 
            document.documentElement.scrollTop;
        setHeight(winScroll);

        if (winScroll < heightToHideFrom) {  
            isVisible && setIsVisible(false);
        } else {
            setIsVisible(true);
        }  
    }, [isVisible])

    useEffect(() => {   
        window.addEventListener("scroll", listenToScroll);
        return () => 
        window.removeEventListener("scroll", listenToScroll); 
    }, [listenToScroll])

    if(!isVisible) return
    return (
        <div className="pointer-events-none fixed w-full flex justify-end left-0 bottom-0 z-50" style={{opacity: (height - heightToHideFrom) / (3 * heightToHideFrom)}}>
            <div className="flex justify-end py-4 px-2 sm:p-4 lg:p-10">
                <a href="#top" className="pointer-events-auto w-12 h-12 bg-primary-darker hover:bg-primary-dark text-fc-dark-brighter flex justify-center items-center rounded-full select-none">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 15l7-7 7 7" />
                    </svg>
                </a>
            </div>
        </div>
    )
}