import { useEffect, useRef } from "react"
import { useState } from "react"

export default function Snake({setSystemMessage}){

    const renderCount = useRef(0)
    class Tile{
        constructor(y, x, move = false){
            this.y = y
            this.x = x
            this.move = move
            this.key = renderCount.current++
        }
    }
    const GRID_SIZE = 25
    const TILE_SIZE = 'w-3 h-3 sm:w-5 sm:h-5'
    const RIGHT = 1
    const LEFT = 2
    const UP = 4
    const DOWN = 8

    // eslint-disable-next-line
    const movementFunction = (args) => {
        let key = args.key
        if(key === 'd' || key === 'ArrowRight'){
            pressHorizontal(false)
        }
        if(key === 'a' || key === 'ArrowLeft'){
            pressHorizontal(true)
        }
        if(key === 'w' || key === 'ArrowUp'){
            pressVertical(true)
        }
        if(key === 's' || key === 'ArrowDown'){
            pressVertical(false)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', movementFunction)

        return () => {
            window.removeEventListener('keydown', movementFunction)
        }
    },[movementFunction])

    const initialGameState = {
        state: 'initial',
        score: 0,
        highScore: 0
    }
    const initialSnake = [
        // new Tile(0, 7, true), new Tile(0, 6, true), new Tile(0, 5, true), new Tile(0, 4, true),
        new Tile(12, 7, true), new Tile(12, 6, true), new Tile(12, 5, true), new Tile(12, 4, true)
    ]
    const initialFood = new Tile(12, 19)

    const [snake, setSnake] = useState(initialSnake)
    const [gameState, setGameState] = useState(initialGameState)
    const lastMove = useRef(1)
    const queuedMove = useRef([])
    const [food, setFood] = useState(initialFood)

    const processing = useRef(false)
    
    const fps = 8
    const timerDuration = 1000 / fps
    const timer = useRef(null)
    timer.current = null

    useEffect(() => {
        let hs = localStorage.getItem('snakeHighScore')
        setGameState({
            ...gameState,
            highScore: hs === null ? 0 : hs
        })
    //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(gameState.state === 'playing'){
            initializeTimer()
        }
        if(gameState.state === 'end'){
            clearTimer()
        }
    //eslint-disable-next-line
    }, [gameState])

    function initializeNew(){
        clearTimer()
        renderCount.current = 0
        setSnake(initialSnake)
        setFood(initialFood)
        lastMove.current = 1
        queuedMove.current = []
        setGameState({
            ...gameState,
            score: 0,
            state: 'initial'
        })
    }

    function randomInt(max, skip = undefined){
        if(skip === undefined)
            return Math.floor(Math.random() * (max + 1));
        return (Math.floor(Math.random() * max) + skip + 1) % (max + 1)
    }

    function getRandomEmptyTile(){
        let map = []
        for(let i = 0; i < GRID_SIZE; i++){
            let row = []
            for(let j = 0; j < GRID_SIZE; j++)
                row.push(null)
            map.push(row)
        }

        snake.forEach((e) => {
            map[e.y][e.x] = e
        })

        let empty = []
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++)
            if(map[i][j] === null) empty.push(new Tile(i, j))
        }

        return empty[randomInt(empty.length - 1)]
    }

    const MOVE_SET = [{y:0, x:1}, {y:0, x:-1}, {y:-1, x:0}, {y:1, x:0}]
    const moveSnake = (s) => {
        if(processing.current) return s
        processing.current = true
        let getMovement = queuedMove.current.length > 0 ? queuedMove.current.shift() : lastMove.current
        lastMove.current = getMovement
        
        getMovement = Math.floor(Math.log2(getMovement))
        let movement = MOVE_SET[getMovement]

        for(let i = s.length - 1; i >= 0; i--){
            let tile = s[i]
            let next = s[i-1]
            if(!tile.move){
                if(tile.x === next.x && tile.y === next.y)
                    tile.move = true
                continue
            }

            if(i !== 0){
                tile.x = next.x
                tile.y = next.y
            } else {
                tile.x = (tile.x + movement.x) % GRID_SIZE
                tile.y = (tile.y + movement.y) % GRID_SIZE
                tile.x = tile.x < 0 ? GRID_SIZE - 1 : tile.x
                tile.y = tile.y < 0 ? GRID_SIZE - 1 : tile.y
            }
        }

        return s
    }

    const initializeTimer = () => {
        if(timer.current !== null) return;
        let before = Date.now();

        let s = JSON.parse(JSON.stringify(snake))
        
        let head = s[0]
        let increaseScore = false
        if(head.x === food.x && head.y === food.y){
            s.push(food)
            setFood(getRandomEmptyTile())
            increaseScore = true
        }

        s = moveSnake(s)
        if(checkCollision(s)){
            setGameState({
                ...gameState,
                state: 'end'
            })
        }
        let delta = before - Date.now();

        timer.current = setTimeout(() => {
            setSnake(s)
            if(increaseScore)
                setGameState(g => {
                    localStorage.setItem('snakeHighScore', Math.max(g.highScore, g.score + 1))
                    return {
                    ...g,
                    score: g.score + 1,
                    highScore: g.score + 1 > g.highScore ? g.score + 1 : g.highScore 
                }})
        }, timerDuration - delta)
    }

    useEffect(() => {
        processing.current = false
        if(gameState.state === 'initial') return
        if(gameState.state === 'end') return
        clearTimer()
        initializeTimer()
    //eslint-disable-next-line
    }, [snake])

    function clearTimer(){
        if(timer.current === null) return

        clearTimeout(timer.current)
        timer.current = null
    }

    function checkCollision(snek){
        let head = snek[0]
        for(let i = 1; i < snek.length; i++){
            let tile = snek[i]
            if(head.x === tile.x && head.y === tile.y)
                return true
        }

        return false
    }

    function pressVertical(isUp){
        if(gameState.state === 'initial')
            setGameState({...gameState, state: 'playing'})
        let next = isUp ? UP : DOWN
        let last = queuedMove.current[queuedMove.current.length - 1]
        if(next === UP && last === DOWN) return
        if(next === DOWN && last === UP) return
        if(queuedMove.current.length === 0){
            if(next === UP && lastMove.current === DOWN) return
            if(next === DOWN && lastMove.current === UP) return
        }
        if(last === next) return
        queuedMove.current.push(next)
    }

    function pressHorizontal(isLeft){
        if(gameState.state === 'initial')
            setGameState({...gameState, state: 'playing'})
        let next = isLeft ? LEFT : RIGHT
        let last = queuedMove.current[queuedMove.current.length - 1]
        if(next === LEFT && last === RIGHT) return
        if(next === RIGHT && last === LEFT) return
        if(queuedMove.current.length === 0){
            if(next === LEFT && lastMove.current === RIGHT) return
            if(next === RIGHT && lastMove.current === LEFT) return
        }
        if(last === next) return
        queuedMove.current.push(next)
    }

    function renderSnake(){
        return snake.map((e, idx) => {
            return <div key={e.key} className={`absolute ${TILE_SIZE} text-fc-dark-brighter text-center flex justify-center items-center
                ${idx === 0 ? "bg-primary-darker" : e.move ? "bg-primary" : "bg-primary-dark"}`}
                style={{top: `calc(${e.y} * ${window.innerWidth < 640 ? "0.75rem":"1.25rem"})`, left: `calc(${e.x} * ${window.innerWidth < 640 ? "0.75rem":"1.25rem"})`, zIndex: e.move === false || idx === 0 ? "1" : "0"}} 
            >{e.val}</div>
        })
    }

    function renderGrid(){
        let row = []
        for(let i = 0; i < GRID_SIZE; i++){
            let col = []
            for(let j = 0; j < GRID_SIZE; j++){
                col.push(
                    <div key={i+""+j} className={`${TILE_SIZE} ${i % 2 === j % 2 ? "bg-neutral2 dark:bg-neutral2-dark" : "bg-neutral3 dark:bg-neutral3-dark"}`}></div>
                )
            }
            row.push(
                <div key={i} className="flex">
                    {col}
                </div>
            )
        }
        return row
    }

    return(
        <section id="game2048" className="min-h-[90vh] xl:py-12 flex justify-center">
            <div className='container px-4 flex justify-center items-center flex-col'>
                <div className="flex flex-col mb-4 items-center">
                    <div className="flex">    
                        <div className="bg-primary-dark text-fc-dark-brighter py-2 px-4 rounded-md mr-4 w-32 sm:w-40">
                            <div className="uppercase text-center text-sm">score</div>
                            <div className="font-semibold text-center text-2xl">{gameState.score}</div>
                        </div>
                        <div className="bg-primary-dark text-fc-dark-brighter py-2 px-4 rounded-md w-32 sm:w-40">
                            <div className="uppercase text-center text-sm">high score</div>
                            <div className="font-semibold text-center text-2xl">{gameState.highScore}</div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center flex-col">
                    <div className="w-full flex justify-center sm:w-fit mb-6 bg-primary-dark hover:bg-primary-darker active:bg-primary-dark transition select-none text-fc-dark-brighter py-2 px-4 rounded-sm cursor-pointer" onClick={initializeNew}>New Game</div>
                    <div id="gamebox" className="p-[0.25rem] bg-neutral1 dark:bg-neutral1-dark relative">
                        <div className="relative">
                            <div className={`absolute ${TILE_SIZE} text-fc-dark-brighter text-center flex justify-center items-center bg-complementary`}
                                style={{top: `calc(${food.y} * ${window.innerWidth < 640 ? "0.75rem":"1.25rem"})`, left: `calc(${food.x} * ${window.innerWidth < 640 ? "0.75rem":"1.25rem"})`}} 
                            ></div>
                            {renderSnake()}
                        </div>
                        {renderGrid()}
                        <div className={`text-3xl text-center items-center justify-center text-fc-dark-brighter font-semibold absolute ${gameState.state === 'initial' ? 'flex' : 'hidden'} bg-black/25 w-full h-full top-0 left-0 z-10`}>
                            Press a Key <br />
                            to <br /> START!
                        </div>
                        <div className={`absolute top-0 left-0 bg-black/25 text-fc-dark-brightest w-full h-full justify-center items-center flex-col z-10`} style={{display: gameState.state === 'end' ? 'flex' : 'none'}}>
                            <div className="text-4xl font-bold">GAME OVER!</div>
                            <div className="bg-complementary-dark hover:bg-complementary transition active:bg-complementary-dark select-none cursor-pointer py-2 px-5 mt-8 rounded-sm"
                            onClick={() => initializeNew()}>Reset</div>
                        </div>
                    </div>
                    <div className="mt-1 flex flex-col justify-center items-center select-none">
                        <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressVertical(true)}>↑</div>
                        <div className="flex">
                            <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressHorizontal(true)}>←</div>
                            <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressVertical(false)}>↓</div>   
                            <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressHorizontal(false)}>→</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}