import { useEffect } from 'react'
import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SystemMessage from '../../utilities/SystemMessage'
import Game2048 from './components/2048/Game2048'
import GameItems from './components/GameItems'
import Header from './components/Header'
import MemoryGame from './components/memory_game/MemoryGame'
import Snake from './components/Snake/Snake'
import TicTacToe from './components/tictactoe/TicTacToe'

export default function Games(){

    const [systemMessage, setSystemMessage] = useState({message: '', type:''})

    useEffect(() => {
        // console.log(systemMessage)
    }, [systemMessage])

    return (
        <BrowserRouter>
        <div className="dark:bg-neutral2-dark min-h-screen dark:text-fc-dark text-fc bg-neutral2">
            <SystemMessage message={systemMessage.message} type={systemMessage.type} setSystemMessage={setSystemMessage}/>
            <Header/>
            <Routes>
                <Route path="" element={<GameItems/>}/>
                <Route path="2048" element={<Game2048 setSystemMessage={setSystemMessage}/>}/>
                <Route path="tictactoe" element={<TicTacToe setSystemMessage={setSystemMessage}/>}/>
                <Route path="snake" element={<Snake setSystemMessage={setSystemMessage}/>}/>
                <Route path="memory_game" element={<MemoryGame setSystemMessage={setSystemMessage}/>}/>
            </Routes>
        </div>
        </BrowserRouter>
    )
}