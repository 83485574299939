import Header from './components/header-nav/Header';
import Contents from './components/contents/Contents';
import ToTopButton from '../../utilities/ToTopButton';

export default function Portofolio(){
    return (
        <div className="dark:bg-neutral2-dark min-h-screen dark:text-fc-dark text-fc bg-neutral2">
            <ToTopButton/>
            <Header></Header>
            <Contents></Contents>
        </div>
    )
}