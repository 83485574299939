import AboutMe from "./AboutMe";
import Experiences from "./Experiences";
import Projects from "./Projects";
import PublicationCompetition from "./PublicationCompetition";
import Skills from "./Skills";
import Footer from '../footer/Footer';

export default function Contents(){
    return(
        <div className="">
            <AboutMe/>
            <Experiences/>
            <Skills/>
            <Projects/>
            <PublicationCompetition/>
            <Footer/>
        </div>
    )
}