import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"

export default function TicTacToe({setSystemMessage}){
    
    const GRID_SIZE = 3
    const [gameState, setGameState] = useState({
        gameArray: [[],[],[]],
        gameStage: 'playing',
        endState: undefined,
        player2: 'user'
    })

    const [playerState, setPlayerState] = useState(0)
    const cpuState = useRef('waiting turn')
    const availableBlock = useRef(GRID_SIZE * GRID_SIZE)

    function initialize(){
        let player2 = localStorage.getItem('tttPlayer2')
        setGameState({
            ...gameState,
            gameArray: 
                [[],[],[]],
                // [[0,1,0],[1,1,0],[]],
                // [[,,1],[],[0,1,0]],
                // [[1,,0],[,,1],[,,0]],
            gameStage: 'playing',
            endState: undefined,
            player2: player2 === null ? 'user' : player2
        })
        setPlayerState(0)
        availableBlock.current = GRID_SIZE * GRID_SIZE
    }

    useEffect(() => {
        initialize()
    }, [])

    function getPlayerIcon(player){
        if(player === undefined) return ""
        return !player ? 
            <div className="w-full h-full relative flex justify-center items-center">
                <div className="absolute h-full w-1 lg:w-2 dark:bg-fc-dark-brightest bg-fc-brightest rotate-45"></div>
                <div className="absolute h-full w-1 lg:w-2 dark:bg-fc-dark-brightest bg-fc-brightest -rotate-45"></div>
            </div> 
            : <div className="w-full h-full border-4 xl:border-8 rounded-full dark:border-fc-dark-brightest border-fc-brightest">
            </div>
    }

    function checkWin(gameArray, localPlayerState){
        let count = 0
        let draw = 0
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++){
                if(gameArray[i][j] === undefined || gameArray[i][j] === null)
                    draw++
                if(gameArray[i][j] === localPlayerState)
                    count++
            }
            if(count === GRID_SIZE) return count
            count = 0
        }
        
        count = 0
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++)
            if(gameArray[j][i] === localPlayerState)
                count++

            if(count === GRID_SIZE) return count
            count = 0
        }

        count = 0
        for(let i = 0; i < GRID_SIZE; i++)
        if(gameArray[i][i] === localPlayerState)
            count++
        if(count === GRID_SIZE) return count

        count = 0
        for(let i = 0; i < GRID_SIZE; i++)
        if(gameArray[i][GRID_SIZE - 1 - i] === localPlayerState)
            count++

        if(count === GRID_SIZE) return count
        if(draw === 0) return GRID_SIZE * GRID_SIZE
        
        return 0
    }

    function gameButtonClick(y, x){
        if(gameState.player2 !== 'user' && playerState === 1 && cpuState.current !== 'placing') return
        if(gameState.gameStage === 'end'){
            setSystemMessage({message:'Round already ended, please start a new round', type:'info'})
            return
        }
        if(gameState.gameArray[y][x] !== undefined){
            setSystemMessage({message:'The place you choose already filled', type:'error'})
            return
        }
        let copyArray = gameState.gameArray
        copyArray[y][x] = playerState
        availableBlock.current--

        setGameState({...gameState, gameArray: copyArray})

        let win = checkWin(copyArray, playerState)
        if(win === 0)
        setPlayerState(playerState ? 0 : 1)
        else
        setGameState({...gameState, gameStage: 'end', endState: win === GRID_SIZE ? `Player ${playerState + 1} wins` : 'Round draw' })
    }

    function gameButton(y, x){
        return <div key={x} onClick={() => gameButtonClick(y, x)} className={`w-24 h-24 lg:w-28 lg:h-28 xl:w-36 xl:h-36 2xl:w-44 2xl:h-44 p-6 flex items-center justify-center ${y !== 2 ? "mb-2" : ""} ${x !== 2 ? "mr-2" : ""} dark:bg-neutral3-dark bg-neutral3`}>
            {getPlayerIcon(gameState.gameArray[y][x])}
        </div>
    }

    function renderGamegrid(){
        let row = []
        for(let i = 0; i < GRID_SIZE; i++){
            let col = []
            for(let j = 0; j < GRID_SIZE; j++){
                col.push(gameButton(i, j))
            }
            row.push(
                <div key={i} className="flex">
                    {col}
                </div>
            )
        }
        return row
    }

    const resetButton = () => {
        return <div className="text-fc-dark-brightest bg-complementary-dark 
        hover:bg-complementary transition active:bg-complementary-dark 
        select-none cursor-pointer py-2 px-5 rounded-sm uppercase text-center
        flex items-center justify-center flex-grow lg:ml-4" 
            onClick={() => initialize()}>
            {gameState.gameStage !== 'playing' ? "reset" : "restart"}
        </div>
    }

    function randomInt(max, skip = undefined){
        if(skip === undefined)
            return Math.floor(Math.random() * (max + 1));
        return (Math.floor(Math.random() * max) + skip + 1) % (max + 1)
    }

    const easyCPU = () => {
        let empty = []
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++){
                if(gameState.gameArray[i][j] === undefined)
                empty.push({y: i, x: j})
            }
        }

        return empty[randomInt(empty.length-1)]
    }

    const mediumCPUCheckWinBlock = (localPlayerState) => {
        let count = 0
        let empty = undefined
        for(let i = 0; i < GRID_SIZE; i++){
            empty = undefined
            for(let j = 0; j < GRID_SIZE; j++){
                if(gameState.gameArray[i][j] === undefined)
                empty = {y: i, x: j}
                if(gameState.gameArray[i][j] === localPlayerState)
                    count++
            }
            if(count === GRID_SIZE - 1 && empty !== undefined) return empty
            count = 0
        }
        
        count = 0
        for(let i = 0; i < GRID_SIZE; i++){
            empty = undefined
            for(let j = 0; j < GRID_SIZE; j++){
                if(gameState.gameArray[j][i] === undefined)
                    empty = {y: j, x: i}
                if(gameState.gameArray[j][i] === localPlayerState)
                    count++
            }
            if(count === GRID_SIZE - 1 && empty !== undefined) return empty
            count = 0
        }

        count = 0
        empty = undefined
        for(let i = 0; i < GRID_SIZE; i++){
            if(gameState.gameArray[i][i] === undefined)
                empty = {y: i, x: i}
            if(gameState.gameArray[i][i] === localPlayerState)
                count++
        }
        if(count === GRID_SIZE - 1 && empty !== undefined) return empty

        count = 0
        empty = undefined
        for(let i = 0; i < GRID_SIZE; i++){
            if(gameState.gameArray[i][GRID_SIZE - 1 - i] === undefined)
                empty = {y: i, x: GRID_SIZE - 1 - i}
            if(gameState.gameArray[i][GRID_SIZE - 1 - i] === localPlayerState)
                count++ 
        }
        if(count === GRID_SIZE - 1 && empty !== undefined) return empty

        return undefined
    }

    const mediumCPU = () => {
        let selected = mediumCPUCheckWinBlock(playerState)
        if(selected !== undefined) return selected
        selected = mediumCPUCheckWinBlock(playerState ? 0 : 1)
        if(selected !== undefined) return selected
        
        let empty = []
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++){
                if(gameState.gameArray[i][j] === undefined)
                empty.push({y: i, x: j})
            }
        }
        return empty[randomInt(empty.length-1)]
    }

    const minimax = (gameArray, moveLeft, depth = 1) => {
        let isMax = depth % 2 === 0
        let bestMove = isMax ? -9999 : 9999
        // console.log(gameArray)
        let drawCount = 0
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++){
                if(gameArray[i][j] === 1 || gameArray[i][j] === 0) continue
                let copyArray = JSON.parse(JSON.stringify(gameArray))
                copyArray[i][j] = isMax ? 1 : 0

                let currentMove
                let win = checkWin(copyArray, isMax ? 1 : 0)
                
                // console.log(win, copyArray)
                if(win === GRID_SIZE)
                    currentMove = (100 * moveLeft) * (isMax ? 1 : -1)
                else if(win === GRID_SIZE * GRID_SIZE)
                    currentMove = 0
                else currentMove = minimax(copyArray, moveLeft - 1, depth + 1)

                if(currentMove === 0) drawCount++

                if(hehe.current && depth === 1)
                    console.log(i, j, depth, currentMove)

                if(isMax) bestMove = Math.max(bestMove, currentMove)
                else bestMove = Math.min(bestMove, currentMove)
            }
        }
        // if(bestMove === 0)
        //     return drawCount * (isMax ? -1 : 1)

        return bestMove
    }

    const hehe = useRef(false)
    const impossibleCPU = () => {
        // if(gameState.gameArray[1][1] === undefined) return {y:1, x:1}
        let bestMove = {score: -9999}
        let listMove = []
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++){
                if(gameState.gameArray[i][j] === 1 || gameState.gameArray[i][j] === 0) continue
                let currentMove = {y:i,x:j,score: -9999}

                let copyArray = JSON.parse(JSON.stringify(gameState.gameArray))
                copyArray[i][j] = playerState
                
                // if(i === 2 && j === 0) hehe.current = true
                let win = checkWin(copyArray, playerState)
                    
                if(win === GRID_SIZE) currentMove.score = availableBlock.current
                else if(win === GRID_SIZE * GRID_SIZE) currentMove.score = 0
                else currentMove.score = minimax(copyArray, availableBlock.current - 1)
                
                // console.log(i, j, 0, currentMove)
                if(bestMove.score < currentMove.score)
                    bestMove = currentMove
                listMove.push(currentMove)
            }
        }

        let bestMoves = []
        for(let i = 0; i < listMove.length; i++)
        if(listMove[i].score === bestMove.score)
            bestMoves.push(listMove[i])

        return bestMoves[randomInt(bestMoves.length - 1)]
    }

    useEffect(() => {
        if(playerState === 0){
            cpuState.current = 'waiting turn'
            // console.log(cpuState.current)
            return
        }
        if(gameState.player2 === 'user') return
        if(cpuState.current !== 'waiting turn') return
        cpuState.current = 'calculating'

        let selected = undefined
        if(gameState.player2 === 'easy') selected = easyCPU()
        if(gameState.player2 === 'medium') selected = mediumCPU()
        if(gameState.player2 === 'impossible') selected = impossibleCPU()

        // console.log(cpuState.current)
        if(selected === undefined) return
        setTimeout(() => {
            cpuState.current = 'placing'
            gameButtonClick(selected.y, selected.x)
            cpuState.current = 'end turn'
            setPlayerState(playerState ? 0 : 1)
        }, 250)
    }, [playerState, gameState.gameArray])

    const changePlayer2 = (e) => {
        let val = e.target.value

        localStorage.setItem('tttPlayer2', val)
        setGameState({
            gameArray: [[],[],[]],
            gameStage: 'playing',
            endState: undefined,
            player2: val
        })
        setPlayerState(0)
        availableBlock.current = GRID_SIZE * GRID_SIZE
    }
    
    return(
        <section id="tictactoe" className="h-[90vh] xl:py-12 flex flex-col items-center justify-center relative">
            <div id="ttt-game" className="container px-4 grow flex items-center flex-col">
                <div className="my-12 flex flex-col">
                    <div className="text-fc-brighter dark:text-fc-dark-brightest">{gameState.player2 !== 'user' && playerState === 1 ? "CPU turn" : gameState.gameStage === 'playing' ? `Player ${playerState + 1} turn` : gameState.endState}</div>
                    {/* {gameState.player2} */}
                </div>
                <div className="bg-neutral2 dark:bg-neutral2-dark">
                    <div>
                        <div className="flex mb-8 flex-col lg:flex-row">
                            <FormControl className="lg:w-[12rem] xl:w-[15rem] 2xl:w-[18rem] text-fc-brighter dark:text-fc-dark-brighter">
                                <InputLabel id="player2-settings-label" className="text-fc-brighter dark:text-fc-dark-brighter">Player2 Settings</InputLabel>
                                <Select
                                    labelId="player2-settings-label"
                                    id="player2-settings"
                                    value={gameState.player2}
                                    label="Player2 Settings"
                                    onChange={changePlayer2}
                                    className="lg:mb-0 mb-3 text-fc-brighter dark:text-fc-dark-brighter"
                                >
                                    <MenuItem value={'user'}>User</MenuItem>
                                    <MenuItem value={'easy'}>CPU - Easy</MenuItem>
                                    <MenuItem value={'medium'}>CPU - Medium</MenuItem>
                                    <MenuItem value={'impossible'}>CPU - Impossible</MenuItem>
                                </Select>
                            </FormControl>
                            {resetButton()}
                        </div>
                        {renderGamegrid()}
                    </div>
                </div>
            </div>
        </section>
    )
}