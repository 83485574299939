import { useRef } from "react"
import { useEffect } from "react"
import { useState } from "react"

export default function Game2048({setSystemMessage}){

    const TILE_HEIGHT = 'h-16 sm:h-24'
    const TILE_WIDTH = 'w-16 sm:w-24'
    const TILE_SIZE = `${TILE_HEIGHT} ${TILE_WIDTH}`
    const GRID_SIZE = 4

    const renderCount = useRef(0)
    const [renderedTiles, setRenderedTiles] = useState([
        [null,null,null,null],
        [null,null,null,null],
        [null,null,null,null],
        [null,null,null,null],
    ])
    const keySpam = useRef(false)
    const [gameState, setGameState] = useState({
        state: 'playing',
        score: 0,
        highScore: 0
    })

    const colorLists = [
        '#0d9165',
        '#147e58',
        '#176b4c',
        '#18593f',
        '#184733',
        '#163628',
        '#12261d',
        '#0c1712',
        '#a71442',
        '#841835',
        '#631829',
        '#000000',
    ]

    function movementFunction2048(args){
        let key = args.key
        if(keySpam.current) return
        keySpam.current = true
        if(key === 'd' || key === 'ArrowRight'){
            pressHorizontal(false)
        }
        if(key === 'a' || key === 'ArrowLeft'){
            pressHorizontal(true)
        }
        if(key === 'w' || key === 'ArrowUp'){
            pressVertical(true)
        }
        if(key === 's' || key === 'ArrowDown'){
            pressVertical(false)
        }
    }

    class Tile{
        constructor(y, x, val = Math.random() < 0.95 ? 2 : 4){
            this.y = y
            this.x = x
            this.val = val
            this.key = renderCount.current++
        }
    }

    function randomInt(max, skip = undefined){
        if(skip === undefined)
            return Math.floor(Math.random() * (max + 1));
        return (Math.floor(Math.random() * max) + skip + 1) % (max + 1)
    }

    function initializeNew(){
        renderCount.current = 0
        localStorage.removeItem('game2048grid')
        setGameState({
            ...gameState,
            state: 'playing',
            score: 0
        })

        let tiles = [
            [null,null,null,null],
            [null,null,null,null],
            [null,null,null,null],
            [null,null,null,null],
        ]
        let empties = []
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++)
            if(tiles[i][j] === null) empties.push({y: i, x: j})
        }
        let idx = randomInt(empties.length - 1)
        let o = empties[idx]
        tiles[o.y][o.x] = new Tile(o.y, o.x)
        idx = randomInt(empties.length - 1, idx)
        o = empties[idx]
        tiles[o.y][o.x] = new Tile(o.y, o.x)

        setRenderedTiles(tiles)
    }

    useEffect(() => {
        let local2048 = localStorage.getItem('game2048grid')
        local2048 = JSON.parse(local2048)

        let hs = localStorage.getItem('game2048highScore')
        if(local2048 === null || local2048.renderCount === 0)
        return () => {
            initializeNew()

            setGameState({
                ...gameState,
                highScore: hs === null ? 0 : hs
            })
        } 
        
        return () => {
            renderCount.current = local2048.renderCount
            setRenderedTiles(local2048.tiles)
            setGameState({
                ...gameState,
                score: local2048.score,
                highScore: hs === null ? 0 : hs
            })
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(gameState.score > localStorage.getItem('game2048highScore'))
            localStorage.setItem('game2048highScore', gameState.score)
    }, [gameState])

    useEffect(() => {
        let nullCount = 0
        for(let i = 0; i < GRID_SIZE; i++)
        for(let j = 0; j < GRID_SIZE; j++)
        if(renderedTiles[i][j] === null) nullCount++

        if(nullCount !== GRID_SIZE * GRID_SIZE && pressVertical(true, true) && pressVertical(false, true)
        && pressHorizontal(true, true) && pressHorizontal(false, true)){
            setGameState({
                ...gameState,
                state: 'end'
            })
            localStorage.setItem('game2048highScore', gameState.highScore)
        }
        window.addEventListener('keydown', movementFunction2048)
        if(nullCount !== GRID_SIZE * GRID_SIZE)
        localStorage.setItem('game2048grid', JSON.stringify({
            renderCount: renderCount.current,
            score: gameState.score,
            tiles: renderedTiles
        }))

        return () => {
            window.removeEventListener('keydown', movementFunction2048)
        }// eslint-disable-next-line
    }, [renderedTiles])

    function updateTiles(tiles){
        tiles = JSON.parse(JSON.stringify(tiles))
        let empties = []
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++)
            if(tiles[i][j] === null) empties.push({y: i, x: j})
        }
        let o = empties[randomInt(empties.length - 1)]
        tiles[o.y][o.x] = new Tile(o.y, o.x)
        setRenderedTiles(tiles)
    }

    function pressHorizontal(isLeft, dummyPress = false){
        if(gameState.state === 'end') return
        let tiles = JSON.parse(JSON.stringify(renderedTiles))
        let noChange = true

        let score = gameState.score
        for(let i = 0; i < GRID_SIZE; i++){
            let anchors = []
            let lastTile = null
            for(let j = isLeft ? 0 : GRID_SIZE - 1;
                isLeft ? j < GRID_SIZE : j >= 0;
                isLeft ? j++ : j--){
                let tile = tiles[i][j]
                if(tile === null) anchors.push(j)
                else {
                    let anchor = -1
                    if(lastTile !== null && lastTile.val === tile.val)
                        anchor = lastTile.x
                    else if(anchors.length > 0)
                        anchor = anchors.shift()

                    if(anchor !== -1){
                        noChange = false
                        tile.x = anchor
                        setTimeout(() => {
                            tiles[i][anchor] = tile
                            tiles[i][j] = null
                        }, 80)
                        anchors.push(j)
                    }
                    
                    if(lastTile !== null && lastTile.x === anchor){
                        setTimeout(()=>{
                            tile.val *= 2
                        }, 80)
                        score += tile.val * 2
                        lastTile = null
                    } else 
                    lastTile = tile
                }
            }
        }
        if(noChange){
            keySpam.current = false
            return true
        }
        if(dummyPress) return false
        setRenderedTiles(tiles)
        setGameState({
            ...gameState,
            score: score,
            highScore: gameState.highScore < score ? score : gameState.highScore
        })
        setTimeout(() => {
            updateTiles(tiles)
            keySpam.current = false
        }, 85)
        return false
    }

    function pressVertical(isUp, dummyPress = false){
        if(gameState.state === 'end') return
        let tiles = JSON.parse(JSON.stringify(renderedTiles))
        let noChange = true

        let score = gameState.score
        for(let i = 0; i < GRID_SIZE; i++){
            let anchors = []
            let lastTile = null
            for(let j = isUp ? 0 : GRID_SIZE - 1;
                isUp ? j < GRID_SIZE : j >= 0;
                isUp ? j++ : j--){
                let tile = tiles[j][i]
                if(tile === null) anchors.push(j)
                else {
                    let anchor = -1
                    if(lastTile !== null && lastTile.val === tile.val)
                        anchor = lastTile.y
                    else if(anchors.length > 0)
                        anchor = anchors.shift()

                    if(anchor !== -1){
                        noChange = false
                        tile.y = anchor
                        setTimeout(() => {
                            tiles[anchor][i] = tile
                            tiles[j][i] = null
                        }, 80)
                        anchors.push(j)
                    }
                    
                    if(lastTile !== null && lastTile.y === anchor){
                        setTimeout(()=>{
                            tile.val *= 2
                        }, 80)
                        score += tile.val * 2
                        lastTile = null
                    } else
                    lastTile = tile
                }
            }
        }
        if(noChange){
            keySpam.current = false
            return true
        }
        if(dummyPress) return false
        setRenderedTiles(tiles)
        setGameState({
            ...gameState,
            score: score,
            highScore: gameState.highScore < score ? score : gameState.highScore
        })
        setTimeout(() => {
            updateTiles(tiles)
            keySpam.current = false
        }, 85)
        return false
    }

    function renderTiles(){
        let ret = []
        for(let i = 0; i < GRID_SIZE; i++){
            for(let j = 0; j < GRID_SIZE; j++){
                let e = renderedTiles[i][j]
                if(e === null) continue
                let colorIdx = Math.floor(Math.log(e.val) / Math.log(2)) - 1
                colorIdx = colorIdx >= colorLists.length ? colorLists.length - 1 : colorIdx
                ret.push(
                    <div key={e.key} className={`absolute ${TILE_SIZE} text-fc-dark-brighter text-center flex justify-center items-center duration-75 transition-[left,top]`}
                        style={{top: `calc(${e.y} * ${window.innerWidth < 640 ? "4rem":"6rem"} + 0.25rem + ${e.y} * 0.5rem)`, left: `calc(${e.x} * ${window.innerWidth < 640 ? "4rem":"6rem"} + 0.25rem + ${e.x} * 0.5rem)`, backgroundColor: colorLists[colorIdx]}}
                    >{e.val}</div>
                )
            }
        }
        return ret
    }

    function renderGrid(){
        let row = []
        for(let i = 0; i < GRID_SIZE; i++){
            let col = []
            for(let j = 0; j < GRID_SIZE; j++){
                col.push(
                    <div key={j} className={`m-1 ${TILE_SIZE} flex justify-center items-center dark:bg-neutral3-dark bg-neutral3`}>
                    </div>
                )
            }
            row.push(
                <div key={i} className="flex">
                    {col}
                </div>
            )
        }
        return row
    }

    return(
        <section id="game2048" className="min-h-[90vh] xl:py-12 flex justify-center">
            <div className='container px-4 flex justify-center items-center flex-col'>
                <div className="flex flex-col mb-4 items-center">
                    <div className="flex">    
                        <div className="bg-primary-dark text-fc-dark-brighter py-2 px-4 rounded-md mr-4 w-32 sm:w-40">
                            <div className="uppercase text-center text-sm">score</div>
                            <div className="font-semibold text-center text-2xl">{gameState.score}</div>
                        </div>
                        <div className="bg-primary-dark text-fc-dark-brighter py-2 px-4 rounded-md w-32 sm:w-40">
                            <div className="uppercase text-center text-sm">high score</div>
                            <div className="font-semibold text-center text-2xl">{gameState.highScore}</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="sm:w-fit w-full flex justify-center mb-6 bg-primary-dark hover:bg-primary-darker active:bg-primary-dark transition select-none text-fc-dark-brighter py-2 px-4 rounded-sm cursor-pointer" onClick={initializeNew}>New Game</div>
                    <div id="gamebox" className="bg-neutral1 dark:bg-neutral1-dark p-1 relative">
                        <div className="relative">
                            {renderTiles()}
                        </div>
                        {renderGrid()}
                        <div className={`absolute top-0 left-0 bg-black/25 text-fc-dark-brightest w-full h-full justify-center items-center flex-col`} style={{display: gameState.state === 'end' ? 'flex' : 'none'}}>
                            <div className="text-4xl font-bold">GAME OVER!</div>
                            <div className="bg-complementary-dark hover:bg-complementary transition active:bg-complementary-dark select-none cursor-pointer py-2 px-5 mt-8 rounded-sm"
                            onClick={() => initializeNew()}>Reset</div>
                        </div>
                    </div>
                    <div className="mt-4 flex flex-col justify-center items-center select-none">
                        <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressVertical(true)}>↑</div>
                        <div className="flex">
                            <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressHorizontal(true)}>←</div>
                            <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressVertical(false)}>↓</div>   
                            <div className="w-12 h-12 bg-primary-dark flex justify-center items-center text-fc-dark-brighter m-1" onClick={() => pressHorizontal(false)}>→</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}