import Footer from "./jsx/Footer";
import Header from "./jsx/Header";
import Nav from "./jsx/Nav";
import './css/header.css';
import './css/footer.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./jsx/Content/Home";
import { useEffect } from "react";
import Lore from "./jsx/Content/Lore";
import PreRegister from "./jsx/Content/PreRegister";
import Characters from "./jsx/Content/Characters";
import Galleries from "./jsx/Content/Galleries";

export default function ProjectHCI(){
    
    useEffect(() => {
        document.title = 'Xtream Chaser'
    },[])
    
    return(
        <BrowserRouter>
            <div className="dark:bg-neutral2-dark min-h-screen z-0 dark:text-fc-dark text-fc bg-neutral2 relative">
                <script src="./js/jQuery.js"></script>
                <Header/>
                <Nav/>
                <div id="content">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="galleries" element={<Galleries/>}/>
                        <Route path="lore" element={<Lore/>}/>
                        <Route path="characters" element={<Characters/>}/>
                        <Route path="preRegister" element={<PreRegister/>}/>
                    </Routes>
                </div>
                <Footer/>
            </div>
        </BrowserRouter>
    )
}