import { useEffect, useRef, useState } from "react";

export default function SlideInDiv({id, className, onClick, children, initialize, isLeft=true}){
    const domRef = useRef();
    
    const [isVisible, setVisible] = useState(false);
  
    useEffect(() => {
      const copy = domRef.current
      const observer = new IntersectionObserver(entries => {
        // In your case there's only one element to observe:     
        if (entries[0].isIntersecting) {
          
          // Not possible to set it back to false like this:
          setVisible(true);
          if(initialize)
          initialize()
          
          // No need to keep observing:
          observer.unobserve(copy);
        }
      });
      
      observer.observe(copy);
      
      return () => observer.unobserve(copy);
    }, [initialize]);
  
    return (<div ref={ domRef } id={id} onClick={onClick} 
        className={`${ isVisible ? 'visible' : 'invisible ' + (isLeft ? 'xl:translate-x-full' : 'xl:-translate-x-full') } 
        duration-500 transition-all ${className}`}>{ children }</div>);
  };