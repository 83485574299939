import '../../css/lore.css'

export default function Lore(){
    return(
        <div id="lore">
            <div id="lore-container">
                <div class="lore" id="birthofgoddess">
                    <div class="lore-header"></div>
                    <div class="lore-triangle-bottom"></div>
                    <div class="lore-content">
                        <h1>Birth of the Goddess</h1>
                        <div>&emsp;&emsp;&emsp;In the beginning, there lived the god of Darkness, Henir, and the goddess of light, Elria. Elria created the material realm, the demon realm's creation followed as a result. The Goddess Elria tasked the Goddess Ishmael to become a steward of the material world.</div>
                    </div>
                    <div class="lore-triangle-top"></div>
                </div>
                <div class="lore" id="contient">
                    <div class="lore-header"></div>
                    <div class="lore-triangle-bottom"></div>
                    <div class="lore-content">
                        <h1>Elrios, Continent of the El Life Crystal</h1>
                        <div>
                            The El bringing life to the land  <br/>
                            &emsp;&emsp;&emsp;There was darkness in the material world and Chaos was born out of it. It wasn't easy to look for any signs of life on this huge continent. To protect the material realm, Ishmael sent a huge gem to the heart of the continent like lightning. Soon after, the continent thrived with life and became a place of abundance. All the plants that were once dead came back to life and greeneries began to thrive in the bare, dry land. The ocean became alive, a river began to flow into the forests giving it life. The peaceful wind brought life back onto the land and blew away the flame. People and animals appeared to relish in the energy of the revived land <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The land became a blessed place, and just as people and animals began to appear, a breath of life seemed to emanate from the gem. The gem would shine and glisten on its own and give energy all around. People would come from many different places to admire the gem they would call El. The land it revived would be named Elrios.
                        </div>
                    </div>
                    <div class="lore-triangle-top"></div>
                </div>
                <div class="lore" id="monsteroutbreak">
                    <div class="lore-header"></div>
                    <div class="lore-triangle-bottom"></div>
                    <div class="lore-content">
                        <h1>The Monster Outbreak and the Elrian</h1>
                        <div>
                            &emsp;&emsp;&emsp;The Elrios continent became a prosperous land but it still had traces of its influences from the Demon realm. In a dark forest, just North of where the El had settled, a dark energy seeped through the gap in the dimension and turned the creatures in the forest into monsters. One day when a large amount of dark energy was seeping rampantly, these monsters came out of the forest to attack the people. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;When the citizens became paralyzed in fear, a general with an emblem of the Sun rallied his army against the monsters to defeat them. That general was not only a skilled warrior but he seemed to be knowledgeable in harnessing the El's power to fight. After that fateful event, more and more people who could harness the power of the El appeared. The Goddess Ishmael granted the power to control El to the children of those who settled near it. The common people started following them and turned them into leaders. These people have come to be known as Elrians. The Elrians prospered and established an Elrian Kingdom.
                        </div>
                    </div>
                    <div class="lore-triangle-top"></div>
                </div>
                <div class="lore" id="ellady">
                    <div class="lore-header"></div>
                    <div class="lore-triangle-bottom"></div>
                    <div class="lore-content">
                        <h1>Nasod and The El Lady</h1>
                        <div>
                            The El Lady <br/>
                            &emsp;&emsp;&emsp;Under the guidance of the Elrians, civilization has flourished. The Elrians started teaching people how to harness the amazing power of El. And this prosperous period was known as the brilliant Elrian Kingdom. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;From this civilization, there were some that excelled in theory and the application of El energy. They were called Debrians. These were great minds who where able to overcome their lack of ability to manipulate El through mechanical means. Among the Debrians, a genius named Adrian Nasod was the most prominent. He used the Code to create an El energy device that's able to move with its own. These devices were named after their creator and were called Nasods. And with further development, central A.I.s called Adam and Eve and 2nd generation Nasods were created. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The Nasods and Debrians would accomplish great feats. Their capital, the city of Elrianode was defended by the City in the Sky, Aero City. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;Kings and Debrians defied the gods and tried to show their own sacredness by building a city in the sky in another dimension. This city was called Elysion. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The man-made Nasods could not tell from the proper order of things. For the sake of convenience and the greed of humans, the El's power was gravely misused. Eventually, as more Nasods were created, the power of El deteriorated and the continent became dark again. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;In order to protect the Nasods, Adam started a war with the humans. But this Nasod War ended swiftly when the humans drained the Nasods of their El energy which caused Adam to cease functioning. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;Nasod production stopped and the sea spilled over to the land, dividing the earth up. The El was at its breaking point due to the constant harnessing of its power but with the help of a lady possessed by Ishmael's power, she was able to restore the El. Life slowly returned and the people started to venerate the El Lady <br/>
                            <br/>
                            &emsp;&emsp;&emsp;Those who survived the rough environment started to rebuild the continent with the highly powered Nasods. Although the power of El is not as strong as before, the continent is slowly returning to life. Ever since the revival of the continent, the people greatly venerated the El Lady.
                        </div>
                    </div>
                    <div class="lore-triangle-top"></div>
                </div>
                <div class="lore" id="elmasters">
                    <div class="lore-header"></div>
                    <div class="lore-triangle-bottom"></div>
                    <div class="lore-content">
                        <h1>El Masters</h1>
                        <div>
                            The El Masters. <br/>
                            &emsp;&emsp;&emsp;Everyone rejoiced in the blessed life again and people started to preserve the power of El that the El Lady left behind. The Masters were chosen to govern the El, and they in turn found a new El Lady to watch over it. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The first master, Master of Fire is Rosso. The power of fire is the power of passion, and there is joy to prevent the strength of the fire from overflowing. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The second master, Master of Earth is Gaia. He possesses the warmth and richness of the land and protects the continent of Elrios. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The third master, Master of Water is Denif. A combination of coolness and intelligence controls the sea so that it does not overflow onto the land. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The fourth master, Master of Wind is Ventus. The power of the wind energy keeps the universe in motion. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The fifth master, Master of the Sun is Solace. Elrios was revived due to the power of life and the birth of the sun's power. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The sixth master, Master of the Moon is Ebalon. The power of the moon gives the people of Elrios comfort and security. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;Each Master was endowed with different skills in order to use the El's power to govern Elrios. They circulate the El's energy to fertilize the land while calming the storm, thus giving rise to Nasod again.
                        </div>
                    </div>
                    <div class="lore-triangle-top"></div>
                </div>
                <div class="lore" id="festival">
                    <div class="lore-header"></div>
                    <div class="lore-triangle-bottom"></div>
                    <div class="lore-content">
                        <h1>Harmony Festival, and the Last Day</h1>
                        <div>
                            The Harmony Festival <br/>
                            &emsp;&emsp;&emsp;In Elrios, a special event takes place once every 3 years. When the Sun and Moon become one, the sky will become dark and all living creatures spend this time to unwind and enjoy each other's company. The purpose of this event is to let the El's natural energy prosper and unite all; it was called the Harmony Festival. This day was indeed the most peaceful and tranquil in Elrios. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The El looks natural and full of spirit, but in reality, it is actually at the most unstable state. If the El facilitates the circulation of energy on its own, then it will become defenseless. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;During the Harmony Festival, the El tower was attacked by Solace, the Master of the Sun. Solace felt sympathy for the lady who watches over the El, and so he went on a mission to free her. As the Festival continued, the El Lady and Solace had disappeared together. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;Before the Harmony Festival came to an end, Solace's followers and those who had guarded the tower had gotten in a dispute. In the most horrendous hours, the El Lady who had deserted the El was very unstable and could not overcome the turmoil. A tremendous uproar ensued and explosions erupted everywhere. The El exploded into tiny shards that had been misplaced all over the Elrios continent.
                        </div>
                    </div>
                    <div class="lore-triangle-top"></div>
                </div>
                <div class="lore" id="eksupulosion">
                    <div class="lore-header"></div>
                    <div class="lore-triangle-bottom"></div>
                    <div class="lore-content">
                        <h1>El Explosion</h1>
                        <div>
                            The El Explosion <br/>
                            &emsp;&emsp;&emsp;The large explosion had caused the El to break into millions of pieces and the shards had been dispersed throughout the whole continent. It had even spread through the wilderness, mountain crevices, and the deep river swamps. The Nasods and human civilization had ceased to exist. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;Survivors could only breathe in places where El Shards existed, and so it has caused many people to reside in areas where there were large El Shards. Soon, a massive earthquake split up the lands. Tremors continued for several months, and this eventually led to the continent splitting in two. Communities had been divided and the remaining masters have settled near large El Shards in order to restore the power of El. <br/>
                            <br/>
                            &emsp;&emsp;&emsp;The remaining people who had followed the Masters sacrificed their lives in order to restore the continent back to the way it used to be. Yet the heirs waited at the tower for the El Lady to return. The Kingdom is overrun with wicked beings that steal the broken El Shards and others that try to protect it. <br/>
                            <br/>
                            The story begins, and it is now in your hands.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}