import '../../css/characters.css'
import elesis from "../../assets/characters/elesis.png"
import ara from "../../assets/characters/ara.png"
import add from "../../assets/characters/add.png"
import luciel from "../../assets/characters/luciel.png"

export default function Characters(){
    return(
        <div id="characters">
            <div className="character">
                <div className="character-container elesis">
                    <div className="character-name">
                        Elesis
                        <hr/>
                        Knight
                    </div>
                    <div className="character-image">
                        <img src={elesis} alt=""/>
                    </div>
                </div>
                <div className="character-description elesis">
                    Elesis is Elsword's older sister and sword-fighting mentor. At 16, she had already traveled far to become a better swordfighter. She returns to Ruben to join her brother in training, only to part ways again after enduring a vicious attack from the demons. <br/>
                    <br/>
                    With a mission to protect Elrios from an absolute demon invasion, Elesis takes on the road once more. This may be the best way to keep her brother and their homeland safe. While she does not know when, Elesis eagerly looks forward to someday reuniting with her beloved brother.
                </div>
            </div>
            <div className="character">
                <div className="character-container ara">
                    <div className="character-name">
                        Ara
                        <hr/>
                        Little Xia
                    </div>
                    <div className="character-image">
                        <img src={ara} alt=""/>
                    </div>
                </div>
                <div className="character-description ara">
                    The young lady Ara of the Haan warrior family in Fluone's Northern Empire is a skilled martial artist specializing in the spear. One day, she came very close to death when her brother Aren, now known as Ran, came upon the Dark El, transformed into a demon, and led other demons to attack and destroy their village. <br/>
                    <br/>
                    At that moment, she found the heirloom—an old hairpin, from which a spirit's powers emerged to help her drive the demons away. It was the spirit of the nine-tailed fox named Eun. The spirit agrees to keep lending Ara her abilities in order to find Aren, and in return, Ara promises to help Eun find El that is strong enough to set her free from the heirloom. In awakening, Eun's mythical presence takes over Ara. Soon, in her journey to find Ran, Ara meets Elsword and the El Search Party. Together, they may yet recover Aren and the El.
                </div>
            </div>
            <div className="character">
                <div className="character-container add">
                    <div className="character-name">
                        Add
                        <hr/>
                        Tracer
                    </div>
                    <div className="character-image">
                        <img src={add} alt=""/>
                    </div>
                </div>
                <div className="character-description add">
                    A descendant of a family that studied Nasods, Add is an intelligent mechanic who had inherited the fascination for the robotic race. When his entire family was captured and slain for investigating the Nasods' forbidden secrets, Add was forced into slavery. While trying to escape, he fell into an ancient library and was trapped for a long time. Here, he continued his study of Nasods and in due time, he invented a weapon called the Nasod Dynamo. He eventually used the weapon to escape the library before it could collapse on him. <br/>
                    <br/>
                    Ridden with insanity due to isolation, Add took to the road to find rare Nasod codes, but discovered that the library's time distortion had sent him far into the future when the Nasod race had already fallen. He tried to return to the past at first, but then he changed his mind when he found traces of Eve.
                </div>
            </div>
            <div className="character">
                <div className="character-container luciel">
                    <div className="character-name">
                        Lu/Ciel
                        <hr/>
                        Demons
                    </div>
                    <div className="character-image">
                        <img src={luciel} alt=""/>
                    </div>
                </div>
                <div className="character-description luciel">
                    The demon monarch Lu emerges in Elrios as a small child after using up all her mana to escape the realm of demons. Due to her servant's betrayal, she has lost all her power and possessions. A hitman named Ciel finds Lu in her weak state and takes her in. Her name is all she remembers. Before long, assassins from the demon world come for Lu, and Ciel gives his life to protect her. <br/>
                    <br/>
                    Just then, Lu remembers everything. A mystical contract allows her to revive Ciel as a half-demon, and he is bound to serve her in return. Now, with Ciel's help, Lu sets out to take everything back. Together, they are Luciel, a dangerous duo in pursuit of demons that plague the land.
                </div>
            </div>
        </div>
    )
}