import './App.css';
import Games from './website/games/Games';
import Portofolio from './website/portofolio/Portofolio';
import ProjectHCI from './website/ProjectHCI/ProjectHCI';

function App() {
  // var jsdom = require("jsdom").jsdom;
  // global.$ = require('jquery/dist/jquery')(jsdom().createWindow());

  if(window.location.host.split(".")[0] === "projhci")
    return (
      <ProjectHCI/>
    )

  if (window.location.host.split(".")[0] === "games")
    return (
      <Games/>
    )

  return (
    <Portofolio/>
  )
}

export default App;
