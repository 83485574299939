import { useEffect, useState } from 'react'
import leetcode from '../website/portofolio/images/leetcode.png'
import leetcode_dark from '../website/portofolio/images/leetcode_dark.png'
import FadeInDiv from './FadeInDiv'

export default function LeetCodeCard() {
    const [leetCodeStats, setLeetCodeStats] = useState(undefined)
    const [percentageSolved, setPercentageSolved] = useState(0)

    useEffect(() => {
        fetch("https://leetcode-stats-api.herokuapp.com/yumazine")
        .then(res => {
            if(res.ok){
                return res.json()
            }
            throw res
        })
        .then(data => {
            setLeetCodeStats(data)
            // console.log(data)
        })
        .catch(error => {
            console.log("Failed to fetch: ", error)
        })
    }, [])

    useEffect(() => {
        if(leetCodeStats === undefined) return
        setPercentageSolved(parseInt(leetCodeStats.totalSolved / leetCodeStats.totalQuestions * 100))
    }, [leetCodeStats])

    if(leetCodeStats === undefined) 
        return (
            <a href="https://leetcode.com/yumazine/" className='w-full h-full max-h-40 p-20 flex items-center'>
                <img className='dark:hidden' src={leetcode} alt="LeetCode"/>
                <img className='hidden dark:block' src={leetcode_dark} alt="LeetCode"/>
            </a>
        )
    return (
        <a href="https://leetcode.com/yumazine/" className='z-20' target="_blank" rel="noopener noreferrer">
        <FadeInDiv className='relative'>
            <div className='rounded-md px-3 py-2'>
                <div className='flex text-xs'>
                    <img className='h-5 dark:hidden' src={leetcode} alt="LeetCode"/>
                    <img className='h-5 hidden dark:block' src={leetcode_dark} alt="LeetCode"/>
                    <div className='ml-1 align-text-bottom flex items-end'>Solved Problems</div>
                </div>
                {/* {parseInt(leetCodeStats.totalSolved / leetCodeStats.totalQuestions * 100)} */}
                <div className='text-xs text-end pr-3'>#{leetCodeStats.ranking}</div>
                <div className='mt-3 flex'>
                    <div className='p-1 w-24 h-24 lg2:w-24 lg2:h-24 2xl2:w-28 2xl2:h-28 xl:w-28 xl:h-28 2xl:w-32 2xl:h-32 relative overflow-hidden'>
                        <div className={`${percentageSolved > 50 ? '':'hidden'} absolute w-full h-full top-0 left-0 rounded-full rotate-180 duration-1000 transition-transform origin-center overflow-hidden`}>
                            <div className='w-1/2 h-full bg-[#ffa116]'></div>
                        </div>
                        <div style={{transform: 'rotate(calc(3.6deg * ' + percentageSolved + '))'}}
                        className='absolute w-full h-full top-0 left-0 rounded-full rotate-0 transition-transform duration-1000 overflow-hidden'>
                            <div className='w-1/2 h-full bg-[#ffa116]'></div>
                        </div>
                        <div className={`${percentageSolved > 50 ? 'hidden':''} absolute w-full h-full top-0 left-0`}>
                            <div className='w-1/2 relative h-full dark:bg-neutral1-dark bg-neutral1 overflow-hidden'>
                                <div className='absolute top-0 left-0 lg2:w-24 lg2:h-24 2xl2:w-28 2xl2:h-28 w-24 h-24 xl:w-28 xl:h-28 2xl:w-32 2xl:h-32 p-1 rounded-full'>
                                    <div className='dark:bg-neutral2-dark bg-neutral3 rounded-full p-1 w-full h-full'> 
                                        <div className='dark:bg-neutral1-dark bg-neutral1 w-full h-full rounded-full'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='transition-none dark:bg-neutral2-dark bg-neutral3 w-full h-full p-1 rounded-full'>
                            <div className='transition-none dark:bg-neutral1-dark bg-neutral1 w-full h-full rounded-full'>
                            </div>
                        </div>
                        <div className='absolute w-full h-full p-3 rounded-full z-30 left-0 top-0'>
                            <div className='transition-none dark:bg-neutral1-dark bg-neutral1 w-full h-full rounded-full flex flex-col justify-center items-center '>
                                <div className='text-xl 2xl:text-3xl dark:text-fc-dark-brightest'>{leetCodeStats.totalSolved}</div>
                                <div className='2xl:text-base text-xs'>Solved</div>
                            </div>
                        </div>
                        {/* <div className='absolute bg-red-500 w-full h-full top-0 left-0 rounded-full'></div> */}
                    </div>
                    <div className='ml-3 text-xs 2xl2:text-xs 2xl:text-sm flex flex-col justify-evenly pb-3 pr-3'>
                        <div className='min-w-[10rem] 2xl2:min-w-[10rem] 2xl:min-w-[13rem]'>
                            <div className='flex justify-between'>
                                <div>Easy</div>
                                <div><span className='dark:text-fc-dark-brightest text-fc-brightest font-semibold'>{leetCodeStats.easySolved}</span> / {leetCodeStats.totalEasy}</div>
                            </div>
                            <div className='mt-1 w-full h-[4px] rounded-lg bg-[#2cbb5d40] overflow-hidden'><div className='bg-[#00b8A3] h-full' style={{width: '' + (100 * leetCodeStats.easySolved / leetCodeStats.totalEasy) + '%'}}></div></div>
                        </div>
                        <div className='min-w-[10rem] 2xl2:min-w-[10rem] 2xl:min-w-[13rem]'>
                            <div className='flex justify-between'>
                                <div>Medium</div>
                                <div><span className='dark:text-fc-dark-brightest text-fc-brightest font-semibold'>{leetCodeStats.mediumSolved}</span> / {leetCodeStats.totalMedium}</div>
                            </div>
                            <div className='mt-1 w-full h-[4px] rounded-lg bg-[#ffc01e40] overflow-hidden'><div className='bg-[#ffc01e] h-full' style={{width: '' + (100 * leetCodeStats.mediumSolved / leetCodeStats.totalMedium) + '%'}}></div></div>
                        </div>
                        <div className='min-w-[10rem] 2xl2:min-w-[10rem] 2xl:min-w-[13rem]'>
                            <div className='flex justify-between'>
                                <div>Hard</div>
                                <div><span className='dark:text-fc-dark-brightest text-fc-brightest font-semibold'>{leetCodeStats.hardSolved}</span> / {leetCodeStats.totalHard}</div>
                            </div>
                            <div className='mt-1 w-full h-[4px] rounded-lg bg-[#ef474340] overflow-hidden'><div className='bg-[#ef4743] h-full' style={{width: '' + (100 * leetCodeStats.hardSolved / leetCodeStats.totalHard) + '%'}}></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </FadeInDiv>
        </a>
    )
}