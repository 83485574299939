import '../../css/galleries.css'
import vid from '../../assets/galleries/vid1.mp4'

export default function Galleries(){
    return(
        <div className='relative w-[100vw] min-h-[80vh] flex justify-center items-center' id='galleries'>
            <div>
                <div id="gallery-outer">
                    <div id="gallery-large">
                        <video autoplay muted>
                            <source src={vid}/>
                        </video>
                    </div>
                    <div id="gallery-navigator">
                        <div id="gallery-navigator-mover">
                            <div class="gallery-item item-video" id="vid1" onclick="vidClick()">
                                <div>
                                    <div>
                                        <div class="circle">
                                            <div class="triangle"></div>
                                        </div>
                                    </div>
                                    <div class="item-left-triangle"></div>
                                </div>
                            </div>
                            <div class="gallery-item" id="img1" onclick="imgClick(this.id)">
                                <div>
                                    <div class="item-right-triangle"></div>
                                    <div class="item-left-triangle"></div>
                                </div>
                            </div>
                            <div class="gallery-item" id="img2" onclick="imgClick(this.id)">
                                <div>
                                    <div class="item-right-triangle"></div>
                                    <div class="item-left-triangle"></div>
                                </div>
                            </div>
                            <div class="gallery-item" id="img3" onclick="imgClick(this.id)">
                                <div>
                                    <div class="item-right-triangle"></div>
                                    <div class="item-left-triangle"></div>
                                </div>
                            </div>
                            <div class="gallery-item" id="img4" onclick="imgClick(this.id)">
                                <div>
                                    <div class="item-right-triangle"></div>
                                </div>
                            </div>            
                        </div>
                    </div>
                </div>
                <script src="../js/galleries.js"></script>
            </div>
        </div>
    )
}