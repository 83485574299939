import { useState } from 'react';
import binus from '../../images/working_companies/binus.png';
import blibli from '../../images/working_companies/blibli.png';
import FadeInSection from '../../../../utilities/FadeInSection'
import FadeInDiv from '../../../../utilities/FadeInDiv'

export default function Experiences(){

    const workingExperiences = [
        ["PT. GLOBAL DIGITAL NIAGA (BLIBLI)", blibli, "Full-time", "2022-09-01", null, "h-2/3", [[
            "Software Developer Engineer in Test (Performance)", "2023-09-25", null, [
                "Maintain and improve performance dashboard",
                "Created and run scripts for performance request",
                "Created report for performance request"
            ]
        ],[
            "Software Developer Engineer in Test (RnD)", "2022-09-01", "2023-09-25", [
                "Maintain automation framework",
                "Maintain and improve automation reporting system",
                "Maintain and improve automation report dashboard",
                "Maintain E2e automation",
                "Helped other squads for any queries regarding automation framework or report",
                "Created a dashboard for managing payment proxies",
                "Created scripts to detect content issues for automatic reports",
            ]
        ]]],
        ["Bina Nusantara University", binus, "Full-time", "2019-02-17", "2022-08-31", "w-full", [[
            "Database Administrator Officer", "2022-03-01", "2022-08-31", [
                "Handled and oversee subordinates induction and schedule.",
                "Handled and oversee meetings.",
                "Provide consultations and solutions for subordinates."
            ]
        ],[
            "Database Administrator", "2020-03-01", "2022-02-28", [
                "Handled students' lab exam transaction.",
                "Handled students' lab attendance and eligibility.",
                "Handled students' lab room allocation and instructor data.",
                "Handled automation for periodic data request operation.",
                "Handled platform for downloading data.",
                "Provided data as per requested."
            ]
        ],[
            "Laboratory Instructor", "2019-02-17", "2020-02-29", [
                "An Instructor of 12 distinct courses. (Ex: Artificial Intelligence in Games, Human and Computer Interaction, Object Oriented Analysis & Design, etc.)",
                "Made cases for assignment, exam, and project on some course.",
                "Checked and marked students’ answers.",
                "Researched and developed game application, business analysis and application, web design and programming, networking, and mobile technology application."
            ]
        ]]],
    ]

    function handleDate(date){
        if(date === null) return 'Present'
        let parts = date.split('-');
        let mydate = new Date(parts[0], parts[1] - 1, parts[2]);

        return mydate.toLocaleDateString();
    }

    const [activeIdx, setActiveIdx] = useState(0)
    const bgStyles = [
        "dark:bg-primary-dark border-primary dark:border-primary-dark",
        "dark:bg-neutral3-dark border-neutral3",
        "dark:bg-neutral1 bg-neutral1-dark"
    ]
    const borderStyles = [
        "border-primary",
        "dark:border-neutral1-dark border-primary"
    ]
    const detailTranslateStyles = [
        "lg:top-0",
        "lg:top-[-100%]",
        "lg:top-[-200%]",
        "lg:top-[-300%]",
        "lg:top-[-400%]",
        "lg:top-[-500%]",
        "lg:top-[-600%]",
    ]

    const fontStyles = [
        "text-fc-bright dark:text-fc-dark-bright",
        "text-fc-brighter dark:text-fc-dark-brighter",
    ]

    function renderWorkingHeaders(){
        return workingExperiences.map((item, idx) => {
            return <FadeInDiv key={item[0]} onClick={() => setActiveIdx(idx)} className={`min-w-1/5 `}>
                <div className={`rounded-full mb-5 cursor-pointer transition-all border w-full 
                group hover:scale-105 bg-neutral1 ${bgStyles[+(activeIdx !== idx)]} 
                dark:border-0 flex py-5 xl:py-7 xl:pl-12 xl:pr-14 pl-8 pr-10 
                select-none dark:text-fc-dark-brighter -rotate-6 border-2 
                shadow-lg lg:dark:shadow-neutral2-dark lg:shadow-neutral2`}>
                    <div className='w-14 lg:w-18 xl:w-24 h-14 lg:h-18 xl:h-24 flex justify-center items-center relative'>
                        <div className='rounded-full z-10 w-full h-full overflow-hidden bg-white flex justify-center items-center'>
                            <img className={`${item[5]}`} src={item[1]} alt={item[0]}/>
                        </div>
                        <div className={`absolute rounded-full transition-all ${(activeIdx === idx)?"dark:bg-neutral1-dark":""} bg-primary w-full h-full right-[2px] top-[2px]`}></div>
                    </div>
                    <div className={`mx-4 ${(activeIdx === idx)?"dark:bg-neutral1-dark":""} bg-primary w-[1px]`}></div>
                    <div className={`flex-grow ${fontStyles[+(activeIdx===idx)]}`}>
                        <h1 className={`font-semibold md:text-sm xl:text-base 2xl:text-xl border-b transition-all ${borderStyles[+(activeIdx === idx)]}`}>{item[0]}</h1>
                        <div className='flex justify-between text-xs xl:text-sm'>
                            <div>{handleDate(item[3])} - {handleDate(item[4])}</div>
                            <div className='ml-5'>{item[2]}</div>
                        </div>
                    </div>
                </div>
            </FadeInDiv>
        })
    }

    function renderWorkingDetail(idx){
        let details = workingExperiences[idx][6]
        let len = details.length

        return details.map((d, i) => {
            return <div key={d[0]} className={`${i === 0 ? "mb-1" : "my-1"}`}>
                <div className='flex items-center mb-1'>
                    <div className='mr-5 lg:mr-10'>
                        <div className="w-[11px] h-[11px] bg-complementary"></div>
                    </div>
                    <div className='flex flex-col sm:flex-row sm:justify-between sm:items-center w-full'>
                        <div className='text-lg lg:text-lg xl:text-2xl font-semibold text-primary-dark dark:text-primary-bright'>{d[0]}</div>
                        <div className='text-right w-full sm:w-fit text-xs xl:text-sm text-fc-bright dark:text-fc-dark-bright'>( {handleDate(d[1])} - {handleDate(d[2])} )</div>
                    </div>
                </div>
                <div className='flex items-stretch text-fc-bright dark:text-fc-dark-bright lg:pr-24 xl:pr-40'>
                    <div className={i !== len - 1 ? `w-[1px] ${bgStyles[2]} mr-[5px] ml-[5px]` : "ml-[11px]"}></div>
                    <div className='ml-5 lg:ml-10'>
                        {d[3].map((di) => {
                            return <div key={di} className='text-sm xl:text-base'>
                                • {di}
                            </div>
                        })}
                    </div>
                </div>
            </div>
        })
    }

    function renderWorkingDetails(){
        return <div className={`lg:absolute h-full transition-[top] ${detailTranslateStyles[activeIdx]}`}>
            {workingExperiences.map((item, i) => {
                return <FadeInDiv key={item} className="lg:h-full">
                    <div className='lg:h-full my-5 lg:my-0 lg:py-5 xl:py-7 pr-1'>
                        <div className='lg:hidden flex items-center mb-3 flex-row-reverse'>
                            <div className='w-20 h-20 flex justify-center items-center relative'>
                                <div className='rounded-full z-10 w-full h-full overflow-hidden bg-white flex justify-center items-center'>
                                    <img className={`${item[5]}`} src={item[1]} alt={item[0]}/>
                                </div>
                                <div className={`absolute rounded-full bg-primary transition-all w-full h-full right-[2px] top-[2px]`}></div>
                            </div>
                            <div className='mr-3 flex-grow'>
                                <h1 className={`font-semibold xl:text-xl border-b-2 border-b-primary transition-all`}>{item[0]}</h1>
                                <div className='flex justify-between text-xs xl:text-sm'>
                                    <div>{handleDate(item[3])} - {handleDate(item[4])}</div>
                                    <div className='ml-5'>{item[2]}</div>
                                </div>
                            </div>
                        </div>
                        <div className='h-full overflow-y-auto lg:pl-[calc(2.5rem-5px)] lg:pr-52 xl:pr-72'>
                            {renderWorkingDetail(i)}
                        </div>
                    </div>
                </FadeInDiv>
            })}
        </div>
    }

    return (
        <div id="experiences" className="min-h-[50rem] flex flex-col items-center mt-12 lg:mt-0 lg:pb-12">
            <FadeInDiv className='container px-4 text-3xl xl:text-4xl underline lg:mt-12 lg:mb-8 
            text-fc-brighter dark:text-fc-dark-brighter decoration-primary 
             font-semibold mb-5'>Experiences</FadeInDiv>
            <div className='container px-4 flex flex-grow lg:justify-between justify-center'>
                <FadeInDiv className='lg:w-4/5 w-full
                lg:overflow-hidden rounded-xl relative lg:dark:bg-neutral1-dark lg:bg-neutral1 dark:bg-neutral2-dark bg-neutral2'>
                    {renderWorkingDetails()}
                </FadeInDiv>
                <div className='relative lg:w-1/5 flex flex-col'>
                    <div className='min-w-max lg:block hidden absolute z-10 right-0 top-0'>
                        {renderWorkingHeaders()}
                    </div>
                </div>
            </div>
        </div>
    )
}