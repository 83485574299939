import { useEffect, useRef, useState } from "react";

export default function FadeDiv({id, className, onClick, children, initialize, scale=false, style}){
    const domRef = useRef();
    
    const [isVisible, setVisible] = useState(false);
  
    useEffect(() => {
      const copy = domRef.current
      const observer = new IntersectionObserver(entries => {
      // In your case there's only one element to observe:     
      if (entries[0].isIntersecting) {
      
        // Not possible to set it back to false like this:
        setVisible(true);
        if(initialize)
        initialize()
        
        // No need to keep observing:
        observer.unobserve(copy);
      }
    });
    
    observer.observe(copy);
      
    return () => observer.unobserve(copy);
  }, [initialize])
  
  function getScale() {
    return scale === true ? isVisible ? 'scale-100' : 'scale-75' : ''
  }

  return (<div ref={ domRef } style={style} id={id} onClick={onClick} className={`${ isVisible ? 'opacity-100 visible ' + getScale() : ' opacity-0 invisible ' + getScale() } duration-500 transition-all ${className}`}>{ children }</div>);
};