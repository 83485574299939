export default function Footer(){
    return (
        <div id="footer" className="z-10">
            <hr/>
            <div id="footer-text">   
                <div>Privacy Policy</div>
                <span>|</span>
                <div>Legal</div>
                <span>|</span>
                <div>Subscriber Agreement</div>
                <span>|</span>
                <div>Refunds</div>
                <span>|</span>
                <div><a href="https://www.facebook.com">Facebook</a></div>
                <span>|</span>
                <div><a href="https://www.twitter.com">Twitter</a></div>
            </div>
            <hr/>
            <div>&copy;Copyright 2018 KVAADFEV&trade;.&nbsp;All rights reserved.</div>
        </div>
    )
}