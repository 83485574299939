import { useEffect, useRef, useState } from 'react'
import FadeDiv from '../../../../utilities/FadeDiv'
import FadeInDiv from '../../../../utilities/FadeInDiv'
// import icpc from '../../images/pubcomp/icpc.png'
// import inc from '../../images/pubcomp/inc.png'
// import research from '../../images/pubcomp/research.png'
import icpc from '../../images/pubcomp/icpc_cover.jpeg'
import inc from '../../images/pubcomp/lab_binus.jpg'
import research from '../../images/pubcomp/smart_trolley-min.jpg'

function RenderItem({l, idx}) {
    const ref = useRef()
    const flag = useRef(false)
    const max = 17
    const max2 = 15
    const [style, setStyle] = useState({transform: 'perspective(700px)'})

    function handleDate(date){
        if(date === null) return 'Present'
        let parts = date.split('-');
        let mydate = new Date(parts[0], parts[1] - 1, parts[2]);

        return mydate.toLocaleDateString();
    }

    function mouseLeave() {
        // ref.current.style.transform = 'perspective(700px)'
        setStyle({transform: 'perspective(700px)'})
    }

    useEffect(() => {
        flag.current = false
    }, [style])

    function mouseMove(e) {
        if(window.innerWidth < 1024) return
        if(flag.current) return
        flag.current = true
        const bounds = ref.current.getBoundingClientRect()
        const center = { 
            x: bounds.left + bounds.width / 2,
            y: bounds.top + bounds.height / 2
        }
        const deltaX = (center.x - bounds.left) / max
        const deltaY = (center.y - bounds.top) / max
        // console.log(e.clientX, e.clientY, center)

        const mouseX = e.clientX
        const mouseY = e.clientY
        let rotateX = (mouseY - center.y) / deltaY
        let rotateY = (center.x - mouseX) / deltaX
        rotateX = rotateX > max2 ? max2 : rotateX < -max2 ? -max2 : rotateX
        rotateY = rotateY > max2 ? max2 : rotateY < -max2 ? -max2 : rotateY
        rotateX = Math.round(rotateX * 100) / 100
        rotateY = Math.round(rotateY * 100) / 100

        const newTransform = `
            scale3d(1.07, 1.07, 1.07)
            perspective(700px)
            rotateX(${rotateX}deg)
            rotateY(${rotateY}deg)
        `
        // ref.current.style.transform = newTransform
        setStyle({transform: newTransform})
    }

    return <div className={`xl:w-[30%] lg:w-[32%] flex h-full hover:z-10
        ${idx === 0 ? "items-center" : idx === 1 ? "items-end" : ""}
    `}>
        <FadeDiv>
            <div ref={ref} onMouseMove={mouseMove} onMouseLeave={mouseLeave}
            className={`relative transition duration-300 ease-out origin-center h-fit
            mt-7 lg:mt-0 items-center hover:duration-150 transform-gpu`}
            // style={{transform: 'scale3d(1.05, 1.05, 1.05) perspective(700px) rotateX(-15deg) rotateY(-15deg) ', transformStyle: 'preserve-3d'}}
            style={{...style, transformStyle: 'preserve-3d'}}
            >
                <div style={{transformStyle: 'preserve-3d'}} 
                className={`hidden lg:block absolute -z-10 w-full
                lg:shadow-lg dark:shadow-neutral2-dark
                shadow-neutral2 -translate-z-16
                ${idx === 0 ? "left-3/4 -top-20 2xl:-top-36" : idx === 1 ? "left-3/4 top-1/4 2xl:top-8" : "right-3/4 top-6"} `}>
                    <img src={l[5]} alt="" srcset="" />
                </div>
                <div className={`lg:absolute bottom-8 ${ idx === 0 ? "-right-1/2" : "-left-1/3" } 
                w-full drop-shadow-lg lg:translate-z-12`}
                style={{transformStyle: 'preserve-3d'}}>
                    <div className="flex justify-between w-full flex-col text-fc-brighter 
                    dark:text-fc-dark-bright sm:flex-row lg:flex-col">
                        <div className="w-fit lg:w-full text-xl lg:text-xl xl:text-2xl 
                        transition duration-200 font-semibold underline decoration-primary 
                        underline-offset-2">{l[0]}</div>
                        <div className="text-xs lg:mt-4">{handleDate(l[1])} • {l[2]}</div>
                    </div>
                    <div className="lg:mb-6 w-full flex lg:justify-start justify-end">
                        <a rel="noreferrer" target="_blank" href={l[4]} className="w-fit text-xs xl:text-xs">{l[4]}</a>
                    </div>
                </div>
                <div className="relative z-0 xl:text-sm text-xs lg:tracking-wide 
                dark:text-fc-dark-brighter text-fc-brighter xl:pb-48 lg:pb-40 
                text-justify pt-3 flex-grow rounded-md lg:py-10 lg:px-10 lg:dark:border-none
                lg:dark:bg-neutral3-dark lg:bg-neutral1 lg:border-4 lg:border-neutral3 lg:shadow-lg dark:shadow-neutral2-dark
                shadow-neutral3 lg:rounded-md">{l[3]}</div>
            </div>
        </FadeDiv>
    </div>
}

export default function PublicationCompetition(){

    const lists = [
        ["ICPC Asia Jakarta 2020 Finalist", "2020-12-10", "Team Name - kevdafdev", "The ICPC Asia Jakarta Regional Contest. The ICPC International Collegiate Programming Contest, known as the ICPC, is an annual multi-tiered competitive programming competition among the universities of the world. Our team placed top 38 here.", "https://competition.binus.ac.id/icpc2020/", icpc],
        ["Indonesia National Contest (INC) 2020", "2020-11-29", "Team Name - kevdafdev", "Indonesia regional contest for ICPC. The ICPC International Collegiate Programming Contest, known as the ICPC, is an annual multi-tiered competitive programming competition among the universities of the world. Our team ranked top 33, which qualified us as the ICPC Asia Jakarta 2020 finalist.", "https://competition.binus.ac.id/inc2020/", inc],
        ["Line Follower Smart Trolley System V2", "2021-10-28", "IEEE", "An item to help people for groceries shopping. This smart trolley V2 is designed to have four Mecanum wheels which can go omni-directional moves without the need to rotate. This design is enabling the trolley to move easily from one to another position as desired. The development uses Arduino board as microcontroller to process input from an infrared sensor and RFID reader to support the robot in taking ways to move to a selected location. Finally, the results of the experiment on smart trolley navigation are presented. The result is our smart trolley V2 based on Mecanum wheels and RFID can follow the line and navigate to its destination easily.", "https://ieeexplore.ieee.org/document/9609710", research],
    ]

    function renderList(){
        return lists.map((l, idx) => {
            return <RenderItem key={l} l={l} idx={idx}/>
        })
    }

    return (
        <section id="pubcom" className="dark:bg-neutral1-dark bg-neutral1 relative min-h-[20rem] flex flex-col items-center lg:pt-12">
            <FadeInDiv className='container z-10 px-4 text-3xl xl:text-4xl underline lg:mt-12 lg:mb-8 
            text-fc-brighter dark:text-fc-dark-brighter 
             decoration-primary font-semibold mb-5 lg:text-center'>Publication & Competition</FadeInDiv>
            <FadeInDiv className='mb-16 lg:mb-32 lg:my-10 container z-10 px-4 flex-grow flex lg:justify-between justify-center relative'>
                <div className="w-full flex flex-col lg:flex-row items-center justify-between lg:min-h-[70rem]">
                    {renderList()}
                </div>
            </FadeInDiv>
        </section>
    )
}